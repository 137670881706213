/* eslint-disable */
import HttpService from '../HttpService';
export default class ContractService {
    constructor() {
      this._httpService = new HttpService('/contract/contract');
      this._odooService = new HttpService('/odoo');
    }

    async FindAllByFilters(params) {
      return await this._httpService.get(`/all?verifyPermission=true&${params}`);
    }

    async FindAll() {
    return await this._httpService.get(`/all?verifyPermission=true`);
    }

    async FindById(contractId) {
      return await this._httpService.get(`/migration/${contractId}`);
    }

    async FindByParentId(parentId) {
      return await this._httpService.get(`/subcontracts/${parentId}`);
    }

    async FindByParentIdByFilters(parentId, params) {
      return await this._httpService.get(`/subcontracts/${parentId}?${params}`);
    }

    async ValidateGracePeriod(params) {
      return await this._httpService.post(`/validate-grace-period`, params);
    }

    async FindActiveContractPlanCoverage(contractId, planId) {
      return await this._odooService.get(`/contract-coverage?contractId=${contractId}&planId=${planId}&active=true`)
    }
 };
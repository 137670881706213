<template>
  <div>
    <v-row v-if="documentsList.length > 0 && showEditAndSaveButton" class="mt-4 pl-3 pr-11" justify="space-between">
      <div>
        <p class="title">Upload de Documentos</p>
      </div>
      <div>
        <v-btn class="text--primary" style="background: #d0d2d5" v-if="isDisabledFields" large @click="onClickEdit()">
          <v-icon class="mr-2">
            fas fa-edit
          </v-icon>
          Editar
        </v-btn>
        <v-btn class="text--white" color="primary" v-else large @click="onClickSave()" :disabled="waitingDocumentUpload">
          <v-icon class="mr-2">
            fas fa-save
          </v-icon>
          Salvar
        </v-btn>
      </div>
    </v-row>

    <v-form ref="uploadFilesForm">
      <v-row v-if="documentsList.length > 0" class="mt-4 mb-3 mr-1" style="border-style: dashed; border-width: 2px; border-color: var(--v-primary-base);">
        <v-col cols="12" lg="3" md="5" v-for="(item, index) in documentsList" :key="index">
          <label
            class="label">
            {{ item.description }}{{ fileRulesValidation(item).length > 0 ? '*' : '' }}
          </label>

          <v-row>
            <v-col>
              <v-file-input
                v-model="item.file"
                @change="uploadDocument(item)"
                placeholder="Clique para adicionar"
                accept="application/pdf, image/jpg, image/jpeg, image/png, image/bmp, image/tiff, image/webp"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
                color="textPrimary"
                outlined
                :disabled="waitingDocumentUpload || isDisabledFields"
                :messages="waitingDocumentUpload ? 'Enviando seu documento... Por favor, aguarde.' : ''"
                :loading="waitingDocumentUpload"
                :rules="fileUploadRulesValidation(item)"
                @click:clear="clearDocument(item)"
              >
                <template v-slot:selection="{}">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        small
                        label
                        color="primary"
                        v-on="on"
                      >
                        {{ truncateText(item.file.name, 25) }}
                      </v-chip>
                    </template>
                    <span>{{ item.file.name }}</span>
                  </v-tooltip>
                </template>
              </v-file-input>
            </v-col>
            <v-col class="mb-7" align-self="center" v-if="showDownloadButton">
              <v-btn class="text--white" :loading="item.loadingDownload" color="primary" @click="downloadDocuments(item)">
                Baixar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import MovementDocumentService from '@/services-http/sdi/MovementDocumentService';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import Rules from '@/shared/validators/formRules';

export default {
  name: 'UploadDocumentComponent',
  data: () => ({
    documentsList: [],
    documentsData: [],
    movementDocuments: [],
    isDisabledFields: true,
    waitingDocumentUpload: false,
    isCriticizedBroker: false,
    isCriticizedCarrier: false,
    isEdit: false,
    movementDocumentService: null,
  }),

  props: {
    showEditAndSaveButton: {
      required: false,
      type: Boolean,
    },
    useProps: {
      required: false,
      type: Boolean,
    },
    showDownloadButton: {
      default: true,
      type: Boolean,
    },
    updateParent: {
      default: false,
      type: Boolean,
    },
    movementType: {
      required: false,
    },
    documentParams: {
      required: false,
    },
    attachments: {
      required: false,
    }
  },

  watch: {
    attachments: {
      immediate: true,
      deep: true,
      async handler(attachments) {
        if (!this.documentsList || this.documentsList.length === 0) return;

        this.attachmentsVerification(attachments);
      },
    },
    documentsList: {
      immediate: true,
      deep: true,
      async handler(newV, oldV) {
        if (oldV && !this.areObjectListsDifferent(newV, oldV)) return;

        if (!this.documentParams || Object.keys(this.documentParams).length === 0) return;

        if (!this.checkAtLeastOneKeyFilled(this.documentParams)) return;

        await this.loadDocumentMovements();

        if (this.attachments && this.attachments.length > 0) {
          this.attachmentsVerification(this.attachments);
        }
      },
    }
  },

  methods: {
    attachmentsVerification(attachments) {
      if (attachments && attachments.length > 0) {
        attachments.forEach((attachment) => {
          const documentTypeIndex = this.documentsList.findIndex(
            (documentType) => documentType.id === attachment.documentTypeId
          );

          if (documentTypeIndex !== -1) {
            this.$set(this.documentsList[documentTypeIndex], 'file', {
              name: attachment.titleDocument,
              idRecentlyUploaded: attachment.idRecentlyUploaded,
              id: attachment.id,
              file: attachment.file,
              type: attachment.type,
            });
          }
        });
      }
    },
    validate() {
      if (!this.$refs.uploadFilesForm.validate()) {
        this.isDisabledFields = false;

        return false
      }

      return true;
    },

    areObjectListsDifferent(oldList, newList) {
      if (oldList.length !== newList.length) {
        return true;
      }

      return oldList.some((oldItem, index) => {
        const newItem = newList[index];

        return JSON.stringify(oldItem) !== JSON.stringify(newItem);
      });
    },

    checkAtLeastOneKeyFilled(obj) {
      const validation = Object.keys(obj).some((key) => {
        const value = obj[key];
        return value !== null && value !== undefined && value !== false && value !== true;
      });
      return validation;
    },

    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async loadDocumentType() {
      const route = this.$route.query;
      const { movementType } = route;
      const { insuranceCarrierId } = route;
      const { insuranceCarrierIds } = route;
      const { beneficiaryType } = route;

      let query = `movementType=${movementType}`;

      query += this.useProps
        ? `&insuranceCarrierId=${this.documentParams.carrierId}`
        : `&insuranceCarrierId=${insuranceCarrierId ? insuranceCarrierId : insuranceCarrierIds}`;

      query += `&beneficiaryType=${beneficiaryType}`;

      await this.findDocumentType(query);
    },

    async loadDocumentMovements() {
      if (!this.documentParams) return;

      const query = Object.entries(this.documentParams)
        .filter(([key, value]) =>
          ['contractId', 'carrierId'].includes(key) && value)
        .map(([key, value]) => `${key === 'contractId' ? 'contract.id' : 'carrier.id'}=${value}`)
        .join('&');

      if (!query) return

      const endpoint = `?${query}&size=999`;

      try {
        const response = await new MovementDocumentService().Get(endpoint);
        const result = response.data.content;

        this.movementDocuments = result.filter((movementDocument) =>
        movementDocument.movementRecordTypes && movementDocument.movementRecordTypes.includes(this.movementType)
        );

        if (this.movementType.includes("DEPENDENT")) {
          this.movementDocuments = this.movementDocuments.filter((movementDocument) =>
            this.documentParams.kinshipId
              ? movementDocument.kinships.some((kinship) => kinship.id === this.documentParams.kinshipId)
              : true)
        };

        const priorityConfiguration = this.findConfigurationByPriority(this.movementDocuments);

        if (!priorityConfiguration || Object.keys(priorityConfiguration).length === 0) return;

        const documentListIndex = this.documentsList.findIndex(
          (document) => document.id === priorityConfiguration.documentType.id
          || document.documentTypeId === priorityConfiguration.documentType.id);

        if (documentListIndex === -1) return;

        this.$set(this.documentsList[documentListIndex], 'configuration', priorityConfiguration);

        this.$forceUpdate();
      } catch (error) {
        console.error('Erro ao carregar movimentos:', error);
      }
    },

    fileRulesValidation(item) {
      if (item.configuration && Object.keys(item.configuration).length > 0) {
        if (item.configuration.mandatory)
          return [ item.configuration.documentType.description
            ? item.configuration.documentType.description
            : this.rule.required
          ];

        if (this.documentParams.hasGracePeriod && item.configuration.gracePeriod)
          return [ item.configuration.documentType.description
            ? item.configuration.documentType.description
            : this.rule.required
          ];
      }

      return [];
    },

    fileUploadRulesValidation(item) {
      if (item.file) return [ this.rule.validateUploadFiles(item) ];

      return this.fileRulesValidation(item);
    },

    async findDocumentType(query) {
      const documentTypeService = new DocumentTypeService();

      await documentTypeService.FindAllDocumentType(query).then((response) => {
        this.documentsList = response.data.content.map((element) => ({
          id: element.id,
          name: element.name,
          description: element.description,
          file: null,
        }));
      });
    },

    async uploadDocument(item) {
      if (item) {
        if ((item.file && !this.rule.isValidFileSize(item.file.size)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpg' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png' && item.file.type !== 'image/bmp' && item.file.type !== 'image/tiff' && item.file.type !== 'image/webp'))) {
          return;
        }
        if (item.file !== null && typeof item.file !== 'undefined') {
          await this.clearDocument(item);
          this.waitingDocumentUpload = true;
          const documentTypeId = item.documentTypeId ? item.documentTypeId : item.id;
          await this.documentService.UploadDocument(documentTypeId, item.file).then((response) => {
            this.waitingDocumentUpload = false;

            this.documentsData.push({
              idRecentlyUploaded: response.data,
              id: response.data,
              name: item.name,
              file: item.file,
              type: item.file.type,
              documentTypeId: item.id,
              titleDocument: item.file.name,
            });
            // eslint-disable-next-line no-param-reassign
            item.idRecentlyUploaded = response.data;
          }).catch(() => {
            this.waitingDocumentUpload = false;
          });

          if (this.movementRecord || this.updateParent) {
            this.$emit('observerDocumentData', this.documentsData);
          }
        } else {
          this.clearDocument(item);
        }
      }
    },

    async clearDocument(item) {
      if (item) {
        if (this.documentsData && this.documentsData.length > 0) {
          const documentIndex = this.documentsData.findIndex(
            (document) => document.id === item.id || document.id === item.idRecentlyUploaded);

          if (documentIndex >= 0) {
            this.documentsData.splice(documentIndex, 1);
            this.$emit('observerDocumentData', this.documentsData);
          }
        }
      }
    },

    async loadDocumentsByMovementRecordId() {
      if (sessionStorage.getItem('movementRecord')) {
        this.movementRecord = JSON.parse(sessionStorage.getItem('movementRecord'));
        if (this.$route.name === 'MovementDetail') {
          this.isDisabledFields = true;
        } else {
          this.isDisabledFields = false;
        }
        const queryString = `?movementRecordId=${this.movementRecord.id}`;
        await this.documentService.FindDocumentByFilters(queryString)
          .then((response) => response.data)
          .then(async (result) => {
            if (result && result.length > 0 && this.documentsList.length > 0) {
              this.documentsList = this.documentsList.map((documentType) => {
                const matchingDocument = result.find(
                  (file) => file.documentType.id === documentType.id
                );

                if (matchingDocument) {
                  return {
                    ...documentType,
                    file: new File([matchingDocument.name], matchingDocument.name, { type: 'image/png' }),
                    documentTypeId: documentType.id,
                    id: matchingDocument.id,
                    name: matchingDocument.name,
                    loadingDownload: false,
                  };
                }

                return documentType;
              });
            }
            this.loadDocumentsInData();
          });
      }
    },

    async loadDocumentsInData() {
      this.documentsList.forEach((document) => {
        if (document && document.documentTypeId) {
          this.documentsData.push(document);
        }
      });

      if (this.showEditAndSaveButton) {
        this.onClickSave();
      }
      this.$emit('observerDocumentData', this.documentsData);
    },

    findConfigurationByPriority(documents) {
      const priorityBoth = documents.find(doc => doc.mandatory === true && doc.gracePeriod === true);

      if (priorityBoth && Object.keys(priorityBoth).length > 0) {
        return priorityBoth;
      }

      const priorityMandatory = documents.find(doc => doc.mandatory === true);

      if (priorityMandatory && Object.keys(priorityMandatory).length > 0) {
        return priorityMandatory;
      }

      return documents.find(doc => doc.gracePeriod === true);
    },

    async loadDocumentType() {
      try {
        const query = `movementType=${this.$route.query.movementType}`;
        await new DocumentTypeService().FindAllDocumentType(query)
          .then((response) => {
            this.documentsList = response.data.content.map((element) => ({
            id: element.id,
            name: element.name,
            description: element.description,
            file: null,
          }));
        });
      } catch (error) {
        console.error('Erro ao carregar tipos de documento:', error);
      }
    },

    onClickEdit() {
      this.isDisabledFields = false;
      this.$emit('disabledUploadDocuments', this.isDisabledFields);
    },

    onClickSave() {
      sessionStorage.setItem('documents', JSON.stringify(this.documentFormatted()));

      this.isDisabledFields = true;
      this.$emit('disabledUploadDocuments', this.isDisabledFields);
    },

    documentFormatted() {
      const documentFormatted = this.documentsList.filter((element) => element.file !== null && typeof element.file !== 'undefined').map((element) => ({ id: element.idRecentlyUploaded ? element.idRecentlyUploaded : element.id }));
      return documentFormatted;
    },

    async downloadDocuments(item) {
      if (item && item.file !== null) {
        // eslint-disable-next-line no-param-reassign
        item.loadingDownload = true;
        this.$forceUpdate();
        const id = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
        const queryString = `?idDocuments=${id}`;

        await this.documentService.DownloadFileDocuments(queryString).then((response) => {
          if (response) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const name = this.validateNameInResponseHeaders(contentDispositionHeader);
            const nameDecoded = decodeURIComponent(name);

            fileLink.href = fileURL;
            fileLink.setAttribute('download', nameDecoded);

            document.body.appendChild(fileLink);
            fileLink.click();

            this.selectedItems = [];
            // eslint-disable-next-line no-param-reassign
            item.loadingDownload = false;
            this.$forceUpdate();
          }
        }).catch(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        });
      }
    },

    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },

    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    },
  },

  async created() {
    this.documentService = new DocumentService();
    this.rule = new Rules();

    await this.loadDocumentType();
  },

  async mounted() {
    this.isDisabledFields = false;
    this.verifyTypeOperationForMovement();
    await this.loadDocumentsByMovementRecordId();
    if (this.showEditAndSaveButton) this.isDisabledFields = true;
  },
};
</script>

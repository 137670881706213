export class Tag {
  description?: string;
  id?: number;
}

export class FilePositionInformation {
  startPosition?: number | null;
  endPosition?: number | null;
}

export class FileXlsInformation {
  cellColumn?: string | null;
}

export class TransformationParameter {
  id?: number;
  transformation?: number;
  validation?: any;
  value?: string;
  name?: string;
  isAlphanumeric?: boolean;
  order?: number;
  isField?: boolean;
}

export class Transformation {
  id?: number;
  template?: string;
  column?: number;
  parameters?: TransformationParameter[];
  field?: string;
  order?: number;
  applyBeforeValidation?: any;
}

export class Column {
  id?: number;
  order?: number;
  name?: string;
  content?: any;
  idLayout?: number;
  filePositionInformation?: FilePositionInformation;
  fileXlsInformation?: FileXlsInformation;
  type?: string;
  size?: number;
  fromTo?: string;
  transformations?: Transformation[];
  validations?: any[];
  startPosition?: number | null;
  endPosition?: number | null;
  complementaryLayout?: boolean;
}

export class IdentifierPrefixProperties {
  identifierPrefix?: string | null;
  startPosition?: number | null;
  endPosition?: number | null;
}

export class XlsProperties {
  sheetName?: string;
  cellInit?: number;
  rowInit?: number;
}

export class Layout {
  id?: number;
  name?: string;
  contractId?: number | null;
  contractName?: string | null;
  subContractId?: number | null;
  subContractName?: string | null;
  insuranceCarrierId?: number | null;
  insuranceCarrierName?: string | null;
  financialGroupId?: number;
  financialGroupName?: string;
  interfaceId?: number | null;
  collectorIntegrationConfigId?: number;
  headerLine?: any;
  footerLine?: any;
  replaceSpacesWithUnderline?: boolean | null;
  upperCase?: boolean | null;
  tags?: Tag[];
  fileType?: string;
  layoutType?: string;
  delimiter?: string;
  status?: string;
  queryTransformation?: string;
  isModel?: boolean;
  identifierPrefixProperties?: IdentifierPrefixProperties;
  xlsProperties?: XlsProperties;
  entityType?: string;
  typeIntegrationValue?: string;
  columns?: Column[];
}

export class FinancialGroup {
  id?: number;
  name?: string;
  createDate?: string;
  lastUpdate?: string;
  recordTypeId?: number | null;
  drivePath?: string | null;
  started?: boolean;
  storageCode?: string | null;
  folderTemplate?: string | null;
  generalRuleGroupCompanyId?: string;
  generalRuleGroupBackofficeId?: string;
  domain?: string | null;
  hasAdmContract?: boolean | null;
}

export class LayoutType {
  contracts?: [];
  entityType?: string;
  financialGroupId?: number;
  financialGroups?: FinancialGroup[];
  insuranceCarrierId?: number;
  insuranceCarriers?: [];
  subContracts?: [];
}

export class DataSourceFont {
  id?: number;
  value?: string;
  label?: string;
}


export class LayoutImportModel {
  layout?: Layout;
  layouts?: Layout[];
  layoutType?: LayoutType;
  layoutEntityType?: string;
  dataSourceFonts?: DataSourceFont[];

  constructor() {
    this.layout = {};
    this.layouts = [];
    this.layoutType = {};
    this.layoutEntityType = '';
    this.dataSourceFonts = [
      { id: 2, label: 'Movimentação' },
      { id: 4, label: 'Dados do Titular' },
      { id: 5, label: 'Dados do Contrato' },
    ]
  }
}

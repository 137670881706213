<template>
  <v-container fluid style="margin-bottom: 100px">
    <v-row justify="center">
      <v-col cols="10">
        <v-row>
          <v-col>
            <h2 class="mt-4 secondary--text">
              {{ `${ isEdit ? 'Editar' : isCopy ? 'Cópiar' : ''} Modelo de layout` }}
            </h2>
          </v-col>
          <v-col>
            <v-stepper value="1" style="background-color: transparent" class="elevation-0">
              <v-stepper-header>
                <v-stepper-step step="1">
                  Layout
                </v-stepper-step>
                <v-divider />
                <v-stepper-step step="2">
                  Colunas
                </v-stepper-step>
                <v-divider />
                <v-stepper-step step="3">
                  Transformações e Validações
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-form ref="formLayout">
      <v-row class="mt-5" justify="center">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="4" lg="3">
              <label for="name" class="label">Nome do Layout*</label>
              <v-text-field
                solo
                v-model="layout.name"
                placeholder="Informe o nome do layout"
                name="name"
                maxlength="70"
                :rules="[rule.required]"
                validate-on-blur
              />
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <label class="label">Categoria</label>
              <v-combobox
                v-model="layout.tags"
                :items="tags"
                :search-input.sync="search"
                :hide-no-data="!search"
                :rules="[rule.requiredArray]"
                @keyup.enter="onKeyUpEnter()"
                item-text="description"
                item-value="description"
                hide-selected
                hint="Pressione ENTER para incluir uma nova"
                label="Digite a categoria para incluir ou criar"
                multiple
                deletable-chips
                persistent-hint
                small-chips
                solo
                validate-on-blur
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Nenhum resultado para "<strong>{{ search }}</strong
                        >". Pressione <kbd>enter</kbd> para criar esta categoria
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="10">
          <v-row>
            <v-col cols="12">
              <label class="label">Tipo do Arquivo</label>
            </v-col>
            <v-col cols="12">
              <v-btn-toggle v-model="layout.fileType" mandatory dense>
                <div style="background-color: var(--v-background-base)">
                  <v-btn
                    v-for="(fileType, i) in fileTypes"
                    :key="i"
                    :value="fileType"
                    small
                    active-class="primary"
                    min-width="92"
                    class="mr-6"
                    @input="selectedFileType()"
                  >
                    {{ fileType }}
                  </v-btn>
                </div>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-show="layout.fileType !== 'XLS'" class="mt-10" justify="center">
        <v-col cols="10">
          <v-row>
            <v-col cols="12">
              <label class="label">Tipo do Layout</label>
            </v-col>
            <v-col cols="12">
              <v-btn-toggle mandatory dense v-model="layout.layoutType">
                <div style="background-color: var(--v-background-base)">
                  <v-btn
                    v-for="(layoutType, i) in layoutTypes"
                    :key="i"
                    small
                    :value="layoutType"
                    :disabled="disableLayoutType(layoutType)"
                    active-class="primary"
                    min-width="92"
                    class="mr-6"
                  >
                    {{ layoutType === 'DELIMITED' ? 'Delimitado' : layoutType === 'NONE' ? 'Nenhum' :  layoutType === 'POSITIONAL' ? 'Posicional' : null }}
                  </v-btn>
                </div>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-10">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <label class="label">Cabeçalho</label>
            </v-col>
            <v-col cols="12" md="1">
              <v-simple-checkbox color="primary" v-model="layout.headerLine" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <label class="label">Rodapé</label>
            </v-col>
            <v-col cols="12" md="1">
              <v-simple-checkbox color="primary" v-model="layout.footerLine" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <template v-if="layout.fileType === 'XLS'">
        <v-row justify="center">
          <v-col cols="10">
            <v-row>
              <v-col cols="12" md="3" lg="2">
                <label class="label">Converter para maiúsculo</label>
              </v-col>
              <v-col cols="12" md="1">
                <v-simple-checkbox color="primary" v-model="layout.upperCase" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10">
            <v-row>
              <v-col cols="12" md="3" lg="2">
                <label class="label">Substituir espaço por _(underline)</label>
              </v-col>
              <v-col cols="12" md="1">
                <v-simple-checkbox color="primary" v-model="layout.replaceSpacesWithUnderline" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="10">
            <v-row>
              <v-col cols="12" md="3" lg="2">
                <label class="label">Aba Início*</label>
              </v-col>
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  solo
                  dense
                  v-model="layout.xlsProperties.sheetName"
                  :rules="layout.fileType === 'XLS' ? [rule.required] : []"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-0" justify="center">
          <v-col cols="10">
            <v-row>
              <v-col cols="12" md="3" lg="2">
                <label class="label">Coluna Início*</label>
              </v-col>
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  solo
                  dense
                  v-mask="'##########'"
                  v-model="layout.xlsProperties.cellInit"
                  :rules="layout.fileType === 'XLS' ? [rule.required, rule.validatesNumberGreaterThanZero(layout.xlsProperties.cellInit)] : []"
                  :error-messages="layout.fileType === 'XLS' && Number(layout.xlsProperties.cellInit) === 0 ? 'O valor tem que ser maior que zero' : ''"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-0" justify="center">
          <v-col cols="10">
            <v-row>
              <v-col cols="12" md="3" lg="2">
                <label class="label">Linha Início*</label>
              </v-col>
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  solo
                  dense
                  v-mask="'##########'"
                  v-model="layout.xlsProperties.rowInit"
                  :rules="layout.fileType === 'XLS' ? [rule.required, rule.validatesNumberGreaterThanZero(layout.xlsProperties.rowInit)] : []"
                  :error-messages="layout.fileType === 'XLS' && Number(layout.xlsProperties.rowInit) === 0 ? 'O valor tem que ser maior que zero' : ''"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <v-row v-if="!(this.layout.fileType === 'XLS' || this.layout.layoutType === 'POSITIONAL')" class="mt-4" justify="center">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <label class="label">Delimitadores dos Campos*</label>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-text-field
                solo
                dense
                v-model="layout.delimiter"
                :rules="layout.fileType !== 'XLS' ? [rule.required] : []"
                validate-on-blur
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mt-4" justify="center">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <label class="label">Separador de críticas*</label>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-text-field
                solo
                dense
                v-model="layout.tabErrors"
                :rules="[rule.required]"
                validate-on-blur
                v-mask="'XX'"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mt-4" justify="center">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <label class="label">Qualificador de texto</label>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-text-field
                solo
                dense
                v-model="layout.textQualifier"
                validate-on-blur
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center" class="my-0">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <label class="label">Layout de Referência</label>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-file-input
                v-model="referenceFile"
                prepend-icon=""
                accept="text/plain, .csv, .xls"
                prepend-inner-icon="fa-file"
                solo
                dense
                hide-details
              />
            </v-col>
            <v-col>
              <v-btn
                @click="loadReferenceFile()"
                :disabled="!referenceFile"
                color="primary"
              >
                Carregar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="layout.columns && layout.columns.length > 0" justify="center">

        <v-col cols="10">
          <v-row>
            <v-col justify="center" cols="12" md="8" lg="7">
              <span class="font-weight-bold">Visualização</span>
              <v-data-table
                :headers="headersColumnsTable"
                :items="layout.columns"
                :loading="loadingTable"
                :items-per-page="-1"
                :height="layout.columns && layout.columns.length > 10 ? 500 : null"
                item-key="id"
                class="elevation-1 mt-5"
                hide-default-footer
                hide-default-header
                :fixed-header="true"
              >
                <template v-slot:no-data>
                  <span>Nenhum item foi encontrado.</span>
                </template>
                <template v-slot:header="{ props }">
                <thead class="v-data-table-header">
                  <tr class="table-header">
                    <th style="background-color: #d9d9d9 !important;text-align: center;" v-for="header in props.headers" :key="header.text">
                      <label class="label primary--text">
                        {{ header.text }}
                      </label>
                    </th>
                  </tr>
                </thead>
              </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <Footer
      @cancelar="onClickCancel()"
      @salvar="onClickSave()"
      cancelButtonLabel="Cancelar"
      saveButtonLabel="Avançar"
      disabledBack
    />
  </v-container>
</template>

<script>
import LayoutTagService from '@/services-http/sdi/LayoutTagService';
import LayoutService from '@/services-http/sdi/LayoutService';
import Footer from '@/components/Footer/Footer.vue';
import Rules from '@/shared/validators/formRules';

export default ({
  components: { Footer },
  data: () => ({
    tags: [],
    referenceFile: null,
    search: null,
    fileTypes: ['CSV', 'TXT', 'XLS', 'XML'],
    layoutTypes: ['DELIMITED', 'NONE', 'POSITIONAL'],
    loadingTable: false,
    loadingButtonCancel: false,
    loadingButtonSave: false,

    snackbar: {
      show: false,
      type: '',
      text: '',
    },

    isEdit: false,
    isCopy: false,

    layout: {
      name: '',
      columns: [],
      status: 'ENABLED',
      isModel: true,
      delimiter: '',
      fileType: '',
      textQualifier: '',
      headerLine: false,
      footerLine: false,
      layoutType: '',
      upperCase: false,
      replaceSpacesWithUnderline: false,
      tags: [],
      xlsProperties: {
        sheetName: '',
        cellInit: '',
        rowInit: '',
      },
    },

    headersColumnsTable: [
      { text: 'Campo', value: 'order', align: 'center' },
      { text: 'Nome', value: 'name', align: 'center' },
      { text: 'Conteúdo', value: 'content', align: 'center' },
    ],
  }),

  watch: {
    'layout.fileType': {
      handler(val) {
        if (
            val === 'XLS'
            && this.layout
            && this.layout.xlsProperties
            && !this.layout.xlsProperties.cellInit) {
              this.layout.xlsProperties.cellInit = '1';
              this.layout.xlsProperties.rowInit = '1';
        }
      }
    }
  },

  mounted() {
    this.isEdit = this.$route.name === 'InitialEditConfigs';
    this.isCopy = this.$route.name === 'InitialCopyConfigs';
    this.loadTags();
    if (sessionStorage.getItem('layout')) {
      this.loadSessionStorage();
    }
  },

  methods: {
    loadSessionStorage() {
      this.layout = JSON.parse(sessionStorage.getItem('layout'));
    },
    loadTags() {
      this.layoutTagService.FindAll().then((response) => {
        if (response && response.data) {
          this.tags = response.data;
        }
      });
    },
    disableLayoutType(layoutType) {
      if (this.layout.fileType === 'CSV' && layoutType !== 'DELIMITED') {
        return true;
      }
      if (this.layout.fileType === 'XLS' && layoutType !== 'NONE') {
        return true;
      }
      if (this.layout.fileType === 'TXT' && layoutType === 'NONE') {
        return true;
      }
      return false;
    },
    selectedFileType() {
      this.referenceFile = null;
      if (this.layout.fileType === 'CSV' || this.layout.fileType === 'TXT' || this.layout.fileType === 'XML') {
        this.layout.layoutType = 'DELIMITED';
      }
      if (this.layout.fileType === 'XLS') {
        this.layout.layoutType = '';
        this.layout.delimiter = '';
      } else {
        this.layout.upperCase = false;
        this.layout.replaceSpacesWithUnderline = false;
        this.layout.xlsProperties.sheetName = '';
        this.layout.xlsProperties.cellInit = '';
        this.layout.xlsProperties.rowInit = '';
      }
    },
    async loadReferenceFile() {
      const url = new URLSearchParams();
      const formData = new FormData();
      formData.append('file', this.referenceFile);

      if (this.layout.delimiter && this.layout.delimiter !== '') {
        url.append('delimiter', this.layout.delimiter);
        url.append('textQualifier', this.layout.textQualifier);
      } else {
        url.append('sheetName', this.layout.fileType === 'XLS' ? this.layout.xlsProperties.sheetName : '');
        url.append('cellInit', this.layout.fileType === 'XLS' ? parseInt(this.layout.xlsProperties.cellInit) : '');
        url.append('rowInit', this.layout.fileType === 'XLS' ? parseInt(this.layout.xlsProperties.rowInit) : '');
      }
      this.layoutService.LoadReferenceFile(url, formData).then((response) => {
        if (response && response.data) {
          const { data } = response;
          this.layout.columns = data.map((element) => ({
            ...element,
            transformations: [],
            validations: [],
          }));
        }
      });
    },
    onClickCancel() {
      this.redirectRouter('cancel');
    },
    onClickSave() {
      if (this.$refs.formLayout.validate()) {
        this.formaterTags();
        this.redirectRouter('save');
      } else {
        this.customizeSnackbarMessage('error', 'Confira os campos obrigatórios para prosseguir');
      }
    },
    formaterTags() {
      this.layout.tags = this.layout.tags.map((v) => ({ description: v.description }));
    },
    onKeyUpEnter() {
      if (this.layout.tags) {
        const size = this.layout.tags?.length;
        const lastItem = this.layout.tags[size - 1];

        if (!lastItem.description) {
          const layoutTagItem = { description: `${lastItem}` };
          this.layout.tags.pop();
          this.layout.tags.push(layoutTagItem);
        }
      }
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    redirectRouter(type) {
      if (type === 'save') {
        sessionStorage.setItem('layout', JSON.stringify(this.layout));
        let nameRoute = this.isEdit ? 'ColumnEditConfigs' : this.isCopy ? 'ColumnCopyConfigs' : 'ColumnConfigs';
        this.$router.push(this.$route.query.financialGroupId ? { name: nameRoute, query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: nameRoute });
      } else if (type === 'cancel') {
        if (sessionStorage.getItem('redirectToImport')) {
          sessionStorage.removeItem('layout');
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutImportConfigs' });
        } else {
          this.$router.push({ name: 'LayoutModel' });
        }
      }
    },
    returnMessage(value, type) {
      return value === '0' ? type === 'column' ? 'Valor 0 representa a coluna "A"' : 'Valor 0 representa a linha "1"' : null;
    },
  },

  created() {
    this.layoutTagService = new LayoutTagService();
    this.layoutService = new LayoutService();
    this.rule = new Rules();
  },
});
</script>

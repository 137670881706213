/* eslint-disable */
import HttpService from '../HttpService';
export default class DependentContractedPlanService {
  constructor() {
    this._httpService = new HttpService('/contract/dependent_contracted_plan');
    this._httpInsuredService = new HttpService('/contract/insured');
  }

  async Update(data, id) {
    return await this._httpService.put(`/${id}`, data);
  }

  async FindById(queryString) {
    return await this._httpService.get(`/${queryString}`);
  }

  async FindInsuredByDependentContractedPlanId (dependentContractedPlanId) {
    return await this._httpService.get(`/v2/${dependentContractedPlanId}/contracted_plan_data`);
  }

  async FindAllByFilters(params) {
    return await this._httpService.get(`/find_all_by_filters/permiteds?${params}`);
  }

  async DependentToCreateMovement(params) {
    return await this._httpInsuredService.get(`/dependentToCreateMovement?${params}`);
  }

  async DependentToCreateMovementByInsuredId(beneficiaryContractPlanId, params) {
    return await this._httpInsuredService.get(`/dependentToCreateMovement/${beneficiaryContractPlanId}?${params}&insuredSituation=ACTIVE`);
  }

  async Delete(id) {
    return await this._httpService.delete(`/${id}`);
  }
};

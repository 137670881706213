<template>
  <v-container fluid>
    <v-row class="my-5">
      <v-col class="pa-0">
        <p class="title">
          Dependentes
        </p>
      </v-col>
      <v-col align="end" class="pa-0">
        <v-btn color="primary" class="white--text" large @click="openModalAddDependent()">Adicionar Dependente</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="dependents && dependents.length > 0">
      <v-col cols="12">
        <v-row v-for="(dependent, index) in dependents" :key="index">
          <v-col cols="12 px-0">
            <v-expansion-panels v-model="isPanelExpanded[index]" ref="firstExpansion">
              <v-expansion-panel style="background-color: #ffff">
                <v-expansion-panel-header style="cursor: auto;">
                  <v-row @click.stop>
                    <v-col align-self="center">
                      <h3 :class="dependent.isDelete ? 'red--text' : 'primary--text'" for="dependentName" :style="{ 'text-decoration': dependent.isDelete ? 'line-through' : 'none' }">{{ formatDependentTitle(dependent) }}</h3>
                    </v-col>
                    <v-col class="py-2 mr-5" align="end">
                      <div v-if="!dependent.isDelete">
                        <v-btn class="white--text" outlined color="red" large @click="removeDependent(dependent, index)">
                          <v-icon class="pr-1" color="red" style="font-size: 21px">
                            fas fa-trash-alt
                          </v-icon>
                          Remover dependente
                        </v-btn>
                      </div>
                      <div v-else>
                        <span class="red--text mr-2">
                          Ao concluir, esta movimentação será excluída
                        </span>
                        <v-btn class="white--text" outlined color="primary" large @click="revertRemoveDependent(dependent, index)">
                          <v-icon class="mr-3" color="primary" style="font-size: 21px">
                            mdi mdi-undo
                          </v-icon>
                          Manter dependente
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <DependentForm
                    :isDetail="false"
                    :propsGenders="propsGenders"
                    :propsMaritalStatus="propsMaritalStatus"
                    :propsKinships="propsKinships"
                    :dependent="dependent"
                    @observerDependents="observerDependents"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else class="mt-4 text-center" align="center">
      <v-col cols="12">
        <h3 class="text--disabled">
          Não há dependentes
        </h3>
      </v-col>
    </v-row>

    <ModalCleanSlot ref="ModalCleanSlot" :widthModal="1400" :heightCard="800">
      <div slot="card">
        <h2 class="primary--text mx-3">Novo dependente</h2>
        <v-form ref="formNewDependent" @submit.prevent="addDependent()">
          <DependentForm
            :isDetail="false"
            :propsGenders="propsGenders"
            :propsMaritalStatus="propsMaritalStatus"
            :propsKinships="propsKinships"
            :dependent="newDependent"
          />
          <v-row>
            <v-col cols="12" align="end">
              <v-btn color="primary" outlined class="px-7 mr-5" @click="closeModalAddDependent()">Cancelar</v-btn>
              <v-btn color="primary" type="submit" class="mr-3 px-10">Salvar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </ModalCleanSlot>

    <SnackbarCustomize ref="SnackbarCustomize"/>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import { cloneDeep } from 'lodash';
import DependentForm from '@/components/Beneficiary/Dependent/DependentForm.vue';
import ModalCleanSlot from '@/components/Modals/ModalCleanSlot.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default {
  components: { DependentForm, ModalCleanSlot, SnackbarCustomize },
  data: () => ({
    dependents: [],
    documentsList: [],
    movementRecord: {},
    newDependent: {},
    waitingDocumentUpload: false,
    isCriticizedBroker: false,
    isCriticizedCarrier: false,
    isEdit: false,
    isDelete: false,
    isPanelExpanded: [],
    contractName: '',
    planName: '',
  }),

  props: {
    isDetail: Boolean, propsGenders: Array, propsMaritalStatus: Array, propsKinships: Array, contractedPlanData: Object, holder: Object,
  },

  watch: {
    dependents() {
      this.observerDependents();
    },
    contractedPlanData: {
      deep: true,
      handler(val) {
        this.contractName = val.contractName;
        this.planName = val.planName;
      },
    },
  },

  async mounted() {
    this.verifyTypeOperationForMovement();
    await this.loadDependents();
  },

  methods: {
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    async loadDependents() {
      if (sessionStorage.getItem('movementRecord')) {
        this.movementRecord = await cloneDeep(JSON.parse(sessionStorage.getItem('movementRecord')));
        if (this.movementRecord.dependents && this.movementRecord.dependents.length > 0) {
          const dependents = await cloneDeep(this.movementRecord.dependents);
          await this.formatDependents(dependents);
        }
      }
    },
    async formatDependents(dependents) {
      const dependentMap = dependents.map((dependent) => ({
        insuranceCarrierId: dependent.insuranceCarrierId,
        movementType: dependent.movementType,
        beneficiaryType: dependent.beneficiaryType,
        id: dependent.id,
        cpf: dependent.insuredDocumentNumber,
        name: dependent.insuredName ? this.formatter.formatToTitleCase(dependent.insuredName) : null,
        birthDate: dependent.birthDate ? this.formatter.formatDate(dependent.birthDate) : null,
        stateBirth: dependent.stateBirth,
        gender: dependent.gender,
        motherName: dependent.motherName ? this.formatter.formatToTitleCase(dependent.motherName) : null,
        degreeKinship: dependent.degreeKinship,
        cns: dependent.cns,
        dnv: dependent.dnv ? this.formatter.unmaskDnv(dependent.dnv) : null,
        weight: dependent.weight,
        height: dependent.height,
        maritalStatus: dependent.maritalStatus,
        documents: [],
        isDelete: false,
        documentsList: [],
        registrationComplement: dependent.registrationComplement,
        address: dependent.address ? this.getAddressObject(dependent) : null,
        identityDocumentCountry: dependent.documentCountryIssue ? dependent.documentCountryIssue : null,
        identityDocumentState: dependent.documentStateIssuer ? dependent.documentStateIssuer : null,
        identityDocumentIssuer: dependent.rgDocumentIssuingBody ? dependent.rgDocumentIssuingBody : null,
        identityDocumentEmissionDate: dependent.rgDocumentIssueDate ? this.formatter.formatDate(dependent.rgDocumentIssueDate) : null,
        telephone: dependent.homephoneDDD && dependent.homephoneNumber ? dependent.homephoneDDD + dependent.homephoneNumber : null,
        cellphone: dependent.cellphoneDDD && dependent.cellphoneNumber ? dependent.cellphoneDDD + dependent.cellphoneNumber : null,
        identityDocumentNature: dependent.natureIdentifyDocument ? dependent.natureIdentifyDocument : null,
        email: dependent.email ? dependent.email : null,
        identityDocumentNumber: dependent.rgDocumentIdentify,
      }));

      await this.loadDocumentType(dependentMap);
    },
    getAddressObject(dependent) {
      const address = {};
      address.code = dependent.addressZip;
      address.log = dependent.address;
      address.city = dependent.addressCity;
      address.region = dependent.addressState;
      address.logType = dependent.addressType;
      address.country = dependent.addressCountry;
      address.neighborhood = dependent.addressNeighborhood;
      address.number = dependent.addressNumber;
      address.complement = dependent.addressComplement;
      address.ibgeCode  = dependent.ibgeCode;
      return address;
    },
    async loadDocumentType(dependentMap) {
      const route = this.$route.query;
      const movementType = (this.movementRecord && this.movementRecord.movementType) ? this.movementRecord.movementType : route.movementType;
      const insuranceCarrierId = (this.movementRecord && this.movementRecord.insuranceCarrierId) ? this.movementRecord.insuranceCarrierId : route.insuranceCarrierId;
      const beneficiaryType = (this.movementRecord && this.movementRecord.beneficiaryType) ? this.movementRecord.beneficiaryType : route.beneficiaryType;

      const query = `movementType=${movementType}&insuranceCarrierId=${insuranceCarrierId}&beneficiaryType=${beneficiaryType}`;
      if (dependentMap && dependentMap.length > 0) {
        await this.findDocumentType(dependentMap, query);
      }
    },
    async findDocumentType(dependentMap, query) {
      const response = await this.documentTypeService.FindAllDocumentType(query);
      this.documentsList = await response.data.content.map((element) => ({
        ...element,
        file: null,
      }));
      // eslint-disable-next-line
      for (let dependent of dependentMap) {
        dependent.documentsList = this.documentsList.slice();
        this.loadDocumentsByMovementRecordId(dependent);
      }
    },
    async loadDocumentsByMovementRecordId(dependent) {
      const dependentFormatted = cloneDeep(dependent);
      const queryString = `?movementRecordId=${dependentFormatted.id}`;
      if (dependentFormatted.id) {
        try {
          const response = await this.documentService.FindDocumentByFilters(queryString);
          if (response && response.data && response.data.length > 0) {
            if (dependentFormatted.documentsList && dependentFormatted.documentsList.length > 0) {
              // eslint-disable-next-line no-restricted-syntax, no-const-assign, no-plusplus
              for (let index = 0; index < dependentFormatted.documentsList.length; index++) {
                const documentData = response.data.find((item) => item.documentType.name === dependentFormatted.documentsList[index].name);
                if (typeof documentData !== 'undefined' && dependentFormatted.id === documentData.movementRecordId) {
                  if (documentData) {
                    // eslint-disable-next-line no-param-reassign
                    dependentFormatted.documentsList[index].file = new File([documentData.name], documentData.name, { type: 'image/png' });
                    // eslint-disable-next-line no-param-reassign
                    dependentFormatted.documentsList[index].documentTypeId = documentData.documentType.id;
                    // eslint-disable-next-line no-param-reassign
                    dependentFormatted.documentsList[index].id = documentData.id;
                    // eslint-disable-next-line no-param-reassign
                    dependentFormatted.documentsList[index].loadingDownload = false;
                  }
                }
                this.loadDocumentsInData(dependentFormatted, index);
              }
            }
          }

          const foundDuplicate = this.dependents.some((dep) => dep.cpf === dependentFormatted.cpf || dep.id === dependentFormatted.id);
          if (!foundDuplicate) {
            this.dependents.push(dependentFormatted);
          }
        } catch (error) {
          console.error('Error loading documents:', error);
        }
      }
    },
    async loadDocumentsInData(dependentFormatted, index) {
      if (dependentFormatted.documentsList[index] && dependentFormatted.documentsList[index].documentTypeId) {
        dependentFormatted.documents.push(dependentFormatted.documentsList[index]);
      }
    },
    observerDependents() {
      const dependent = this.dependents;
      this.$emit('observerDependents', dependent);
    },
    openModalAddDependent() {
      this.newDependent = {
        insuranceCarrierId: null,
        movementType: 'INSERT',
        beneficiaryType: 'DEPENDENT',
        id: null,
        cpf: null,
        name: null,
        birthDate: null,
        stateBirth: null,
        gender: null,
        motherName: null,
        degreeKinship: null,
        cns: null,
        dnv: null,
        weight: null,
        height: null,
        maritalStatus: null,
        isDelete: false,
        documents: [],
        documentsList: this.documentsList,
        registrationComplement: null,
        address: {},
        email: null,
        cellphone: null,
        telephone: null,
        identityDocumentNature: null, 
        identityDocumentNumber: null,
        identityDocumentEmissionDate: null,
        identityDocumentIssuer: null,
        identityDocumentState: null,
        identityDocumentCountry: null,
      };
      this.$refs.ModalCleanSlot.open();
    },
    closeModalAddDependent() {
      this.$refs.ModalCleanSlot.close();
    },
    addDependent() {
      this.validateDocument();
      if (this.validateDocument()) {
        if (this.$refs.formNewDependent.validate()) {
          if (this.isAnyFieldFilled()) {
            this.dependents.unshift({ ...this.newDependent });

            this.isPanelExpanded = [0, ...Array(this.dependents.length - 1).fill(1)];
            const { firstExpansion } = this.$refs;
            if (firstExpansion && firstExpansion.$el) {
              firstExpansion.$el.scrollIntoView({ behavior: 'smooth' });
            }
            this.closeModalAddDependent();
          } else {
            this.$refs.SnackbarCustomize.open('error', 'Preencha pelo menos um campo para prosseguir');
          }
        } else {
          this.$refs.SnackbarCustomize.open('error', 'Preencha os campos corretamente para prosseguir');
        }
      }
    },
    validateDocument() {
      const documentNewDependent = this.formatter.removeNonDigit(this.newDependent.cpf);

      if (documentNewDependent === this.holder.document) {
        this.$refs.SnackbarCustomize.open('error', 'Não é possível adicionar o mesmo titular como dependente');
        return false;
      }

      if (this.dependents.some((dependent) => dependent.cpf === this.newDependent.cpf)) {
        this.$refs.SnackbarCustomize.open('error', 'Já existe um dependente com este cpf nessa movimentação');
        return false;
      }

      return true;
    },
    isAnyFieldFilled() {
      const {
        cpf, name, birthDate, stateBirth, gender, motherName, degreeKinship, cns, dnv, weight, height, maritalStatus, registrationComplement,
      } = this.newDependent;

      const obj = {
        cpf, name, birthDate, stateBirth, gender, motherName, degreeKinship, cns, dnv, weight, height, maritalStatus, registrationComplement,
      };

      return Object.entries(obj).some(([key, value]) => {
        if (value !== null && value !== '' && typeof value !== 'undefined') {
          return true;
        }

        if (Array.isArray(value) && value.length > 0) {
          return true;
        }

        return false;
      });
    },
    removeDependent(dependent, index) {
      if (this.dependents[index] === dependent) {
        this.dependents[index].isDelete = true;
      }
      this.isPanelExpanded = [1, ...Array(this.dependents.length - 1).fill(1)];
    },
    revertRemoveDependent(dependent, index) {
      if (this.dependents[index] === dependent) {
        delete this.dependents[index].isDelete;
      }
      this.isPanelExpanded = [1, ...Array(this.dependents.length - 1).fill(1)];
    },
    formatDependentTitle(dependent) {
      let title = '-';
      if (dependent.name) {
        if (dependent.degreeKinship) {
          title = `${this.formatter.formatKinship(dependent.degreeKinship, this.propsKinships)}: ${dependent.name}`;
        } else {
          title = dependent.name;
        }
      } else if (dependent.degreeKinship) {
        title = this.formatter.formatKinship(dependent.degreeKinship, this.propsKinships);
      } else {
        title = 'Novo dependente';
      }

      if (this.contractName && this.planName) {
        const benefit = this.contractName.split(' -')[0];
        title += ` (${benefit} - ${this.planName})`;
      }

      return title;
    },

  },

  created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.documentService = new DocumentService();
    this.documentTypeService = new DocumentTypeService();
  },
};
</script>

const holderFields = new Array<string>('holder_document_number', 'holder_name');
const beneficiaryPlanFields = new Array<string>('insured_document_number', 'insured_name', 'contract', 'subcontract', 'start_date', 'family_group_id', 'plan', 'contract_eligibility_id', 'movement_contract', 'department', 'capacityCode', 'capacityDescription', 'tax_allocation_code', 'tax_allocation_description');
const additionalInformationFields = new Array<string>('birth_date', 'gender', 'marital_status', 'insured_position', 'cost_center_department', 'registration_plate', 'registration_complement', 'previous_registration_plate', 'employment_relationship_id', 'mother_name', 'nationality', 'pis_pasep', 'email', 'professional_card_number', 'cns', 'company_name', 'company_document_number', 'weight', 'height', 'dnv', 'rne', 'cellphone_ddd', 'cellphone_number', 'homephone_ddd', 'homephone_number', 'nature_identify_document', 'rg_document_identify', 'rg_document_issue_date', 'rg_document_issuing_body', 'document_state_issuer', 'document_country_issue', 'state_birth', 'cancellation_reason_code');
const addressFields = new Array<string>('address', 'address_type', 'address_city', 'address_state', 'address_country', 'address_complement', 'address_neighborhood', 'address_number', 'address_zip');
const bankFields = new Array<string>('bank', 'bank_account', 'bank_account_check_digit', 'branch_bank', 'branch_bank_check_digit');

const FORMS = new Map<string, Array<string>>([
  ['beneficiaryPlanInformation', new Array<string>(...holderFields, ...beneficiaryPlanFields)],
  ['beneficiaryAdditionalInformation', new Array<string>(...additionalInformationFields)],
  ['addressBankInformation', new Array<string>(...addressFields, ...bankFields)],
  ['dependents', new Array<string>(...beneficiaryPlanFields, ...additionalInformationFields, 'degree_kinship', 'registration_complement')],
  ['familyGroup', new Array<string>(...holderFields, ...beneficiaryPlanFields, ...additionalInformationFields, ...addressFields, ...bankFields, 'degree_kinship', 'registration_complement')],
]);

export default class MovementRecordFormFieldsToValidate {
  getFieldsToValidate(formName: string): Array<string> | null{
    const fields = FORMS.get(formName) as Array<string>;
    return fields || null;
  }
}

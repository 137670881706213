import { VuexModule, Module, getModule, Mutation } from 'vuex-module-decorators';
import { LayoutImportModel, Layout, LayoutType, Column } from './layout-import-types'
import store from '@/store/index';

@Module({
  namespaced: true,
  name: 'layout-import',
  dynamic: true,
  store
})

class LayoutImportStore extends VuexModule {
  data: LayoutImportModel = new LayoutImportModel();

  get layouts() {
    return this.data.layouts;
  };

  get layout() {
    return this.data.layout;
  };

  get layoutType() {
    return this.data.layoutType;
  };

  get layoutEntityType() {
    return this.data.layoutEntityType;
  }

  get dataSourceFonts() {
    return this.data.dataSourceFonts;
  }

  @Mutation
  resetModule() {
    this.data = new LayoutImportModel();
  };

  @Mutation
  setLayouts(payload: Layout[]) {
    this.data.layouts = payload;
  };

  @Mutation
  setLayoutEntityType(payload: string) {
    this.data.layoutEntityType = payload;
  };

  @Mutation
  setLayout(payload: Layout) {
    this.data.layout = payload;
  };

  @Mutation
  setLayoutHeader(payload: Layout) {
    this.data.layout!.name = payload.name;
    this.data.layout!.financialGroupId = payload.financialGroupId;
    this.data.layout!.insuranceCarrierId = payload.insuranceCarrierId;
    this.data.layout!.contractId = payload.contractId;
    this.data.layout!.subContractId = payload.subContractId;
    this.data.layout!.typeIntegrationValue = payload.typeIntegrationValue;
    this.data.layout!.collectorIntegrationConfigId = payload.collectorIntegrationConfigId;
    this.data.layout!.fileType = payload.fileType;
    this.data.layout!.layoutType = payload.layoutType;
    this.data.layout!.entityType = payload.entityType;
  };

  @Mutation
  setLayoutType(payload: LayoutType) {
    this.data.layoutType = payload;
  };

  @Mutation
  setLayoutColumns(payload: Column[]) {
    if (!this.data.layout) return;

    this.data.layout.columns = payload;
  };
}

export default getModule(LayoutImportStore);

import HttpService from '../HttpService';

export default class ContractBenefitService {
  constructor() {
    this._httpService = new HttpService('/contract/benefit');
  }

  async FindBenefitsByCarrierId(carrierId) {
    return await this._httpService.get(`/find-by-carrier/${carrierId}`);
  }

  async FindBenefitsByContractId(contractId) {
    return await this._httpService.get(`/find-by-contract/${contractId}`);
  }
 };
<template>
  <v-container fluid style="margin-bottom: 100px" ref="top">
    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-row>
          <v-col>
            <h2 class="mt-4 secondary--text">
              {{ `${ isEdit ? 'Editar' : isCopy ? 'Cópiar' : ''} Modelo de layout` }}
              <span v-if="isVisualization" class="visualization-text">(visualização)</span></h2>
          </v-col>
          <v-col>
            <v-stepper value="3" style="background-color: transparent" class="elevation-0">
              <v-stepper-header>
                <v-stepper-step complete step="1">
                  Layout
                </v-stepper-step>
                <v-divider />
                <v-stepper-step complete step="2">
                  Colunas
                </v-stepper-step>
                <v-divider />
                <v-stepper-step step="3">
                  Transformações e Validações
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-5">
      <v-col cols="12" lg="10">
        <v-row>
          <v-col cols="12" md="3">
            <label class="label">Nome do Layout</label>
            <v-text-field
              class="mt-2"
              :value="layout.name"
              solo
              dense
              readonly
              disabled
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-3">
      <v-col cols="12" lg="10">
        <v-row>
          <v-col cols="12" md="3">
            <label class="label">Tipo do Arquivo</label>
            <v-text-field
              class="mt-2"
              :value="layout.fileType"
              solo
              dense
              readonly
              disabled
            />
          </v-col>

          <v-col cols="12" md="3">
            <label class="label">Tipo do Layout</label>
            <v-text-field
              class="mt-2"
              :value="layout.layoutType === 'DELIMITED' ? 'Delimitado' : layout.layoutType === 'NONE' ? 'Nenhum' :  layout.layoutType === 'POSITIONAL' ? 'Posicional' : null"
              solo
              dense
              readonly
              disabled
            />
          </v-col>

          <v-col v-if="layout.delimiter" cols="12" md="3">
            <label class="label">Delimitador</label>
            <v-text-field
              class="mt-2"
              :value="layout.delimiter"
              solo
              dense
              readonly
              disabled
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="10">
        <v-row>
          <v-col cols="12" lg="9" class="px-0">
            <v-form ref="formTransformations">
              <TransformationTable
                :propsLayout="layout"
                :isEdit="isEdit"
                :isCopy="isCopy"
                :isImport="false"
                :propsFromToFields="fromToFields"
                @overlayControll="overlayControll"
                @scrollComponent="scrollComponent"
              />
            </v-form>
          </v-col>
        </v-row>
      </v-col>

      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="120" center>
          <v-row class="justify-center">
            <v-col align="center">
              Processando...
            </v-col>
          </v-row>
        </v-progress-circular>
      </v-overlay>
    </v-row>

    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="widthModal" :heightCardValue="heightCard">
      <div slot="textInfo">
        <v-row v-if="transformationsOrderEmpty && transformationsOrderEmpty.length > 0">
          <v-col cols="12">
            Por favor preencha as ordens das transformações abaixo:
            <div v-for="(transformationEmpty, index) in transformationsOrderEmpty" :key="index">
              <h5 class="red--text" style="word-break: break-word">{{ `Coluna: [${transformationEmpty.column}] - Transformação: [${transformationEmpty.transformation}]` }}</h5>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="parametersValueEmpty && parametersValueEmpty.length > 0" :class="transformationsOrderEmpty && transformationsOrderEmpty.length > 0 ? 'mt-8' : ''" >
          <v-col cols="12">
            Por favor preencha os valores dos parâmetros abaixo:
            <div v-for="(transformationParameterValueEmpty, index) in parametersValueEmpty" :key="index">
              <h5 class="red--text" style="word-break: break-word">{{ `Coluna: [${transformationParameterValueEmpty.column}] - Transformação: [${transformationParameterValueEmpty.transformation}] - Parâmetro: [${transformationParameterValueEmpty.parameter}]` }}</h5>
            </div>
          </v-col>
        </v-row>
      </div>
    </DynamicDialogInformation>

    <SnackbarCustomize ref="SnackbarCustomize" />

    <Footer
      @cancelar="onClickCancel"
      @salvar="onClickSave"
      @voltar="onClickBack"
      cancelButtonLabel="Cancelar"
      saveButtonLabel="Salvar"
      :disabledSave="!isVisualization"
    />
  </v-container>
</template>

<script>
import LayoutService from '@/services-http/sdi/LayoutService';
import Footer from '@/components/Footer/Footer.vue';
import lodash from 'lodash';
import Rules from '@/shared/validators/formRules';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import TransformationTable from '@/components/Layout/Transformations/Table/transformationTable.vue';
import DataSourceService from '@/services-http/sdi/DataSourceService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';

export default ({
  components: {
    Footer,
    DynamicDialogInformation,
    TransformationTable,
    SnackbarCustomize,
  },

  data: () => ({
    layout: {},
    allLayoutColumns: [],
    transformationParameter: {},
    listTransformationsAux: [],
    transformationsOrderEmpty: [],
    parametersValueEmpty: [],
    fromToFields: [],
    widthModal: 800,
    heightCard: 800,
    overlay: true,
    isEdit: false,
    isCopy: false,
    isVisualization: false,
    isValidOrder: true,
  }),

  async mounted() {
    if (sessionStorage.getItem('layout')) {
      await this.loadSessionStorage();
    }
    this.loadDataSource();

    if (this.layout.id) {
      this.isEdit = this.$route.name === 'TransformationEditConfigs';
      this.isCopy = this.$route.name === 'TransformationCopyConfigs';
    }

    if (sessionStorage.getItem('visualization')) {
      this.isVisualization = true;
    }
  },

  methods: {
    async loadSessionStorage() {
      this.layout = JSON.parse(sessionStorage.getItem('layout'));
      this.layout.columns.sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    loadDataSource() {
      this.dataSourceService.FindAll().then((response) => {
        let movementFilter = [];
        if (response && response.data) {
          movementFilter = response.data.find((item) => (this.layout.typeIntegrationValue === 'PAYROLL' ? item.value === 'recebimento_folha' : item.value === 'registro_movimentacao')).columns;
          this.fromToFields = movementFilter;
          this.fromToFields.sort((a, b) => (a.label > b.label ? 1 : -1));
        }
      });
    },
    onClickBack() {
      sessionStorage.setItem('layout', JSON.stringify(this.layout));
      this.redirectRouter('back');
    },
    onClickCancel() {
      this.redirectRouter('cancel');
    },
    async onClickSave() {
      if (this.$refs.formTransformations.validate()) {
        this.overlay = true;
        await this.captureIsCheckedItems();
      } else {
        this.overlay = false;
        this.$refs.SnackbarCustomize.open('error', 'Verifique os campos de "ordem" das transformações das colunas.');
      }
    },
    async captureIsCheckedItems() {
      this.listTransformationsAux = [];
      const layoutColumnsModify = lodash.cloneDeep(this.layout.columns);
      layoutColumnsModify.forEach((element, columnIndex) => {
        element.transformations.forEach((item, transformationIndex) => {
          if (item && item.isChecked === true) {
            this.listTransformationsAux.push({
              ...item,
              columnIndex,
              transformationIndex,
            });
          }
        });
      });
      await this.addRealTransformationInColumns(layoutColumnsModify);
    },
    async addRealTransformationInColumns(layoutColumnsModify) {
      this.listTransformationsAux.forEach((element) => {
        const layoutColumnIndex = layoutColumnsModify.findIndex((item, index) => element.columnIndex === index);
        const layoutColumnTransformationIndex = layoutColumnsModify[layoutColumnIndex].transformations.findIndex((item, index) => element.transformationIndex === index);
        // eslint-disable-next-line no-param-reassign
        layoutColumnsModify[layoutColumnIndex].transformations[layoutColumnTransformationIndex] = element;
      });
      await this.separateIsCheckedInTransformations(layoutColumnsModify);
    },
    async separateIsCheckedInTransformations(layoutColumnsModify) {
      const allLayoutColumnsAux = lodash.cloneDeep(layoutColumnsModify);
      allLayoutColumnsAux.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.transformations = element.transformations.filter((item) => item.isChecked).map((t) => ({
          id: t.id,
          name: t.name,
          order: t.order ? Number(t.order) : '',
          parameters: t.parameters,
          template: t.template,
          field: t.field,
        }));
      });
      const allColumns = await lodash.cloneDeep(allLayoutColumnsAux);
      this.verifyParameters(allColumns);
    },
    verifyParameters(allLayoutColumnsAux) {
      this.transformationsOrderEmpty = [];
      this.parametersValueEmpty = [];
      allLayoutColumnsAux.forEach((column) => {
        column.transformations.forEach((transformation) => {
          if (transformation.order === '') {
            this.transformationsOrderEmpty.push({
              transformation: transformation.name,
              column: column.name,
            });
          }
          if (transformation.name === 'Depara' && transformation.parameters && transformation.parameters.length < 4) {
            this.parametersValueEmpty.push({
              transformation: transformation.name,
              parameter: 'Não há parâmetros suficientes',
              column: column.name,
            });
          }
          if (transformation.parameters && transformation.parameters.length > 0) {
            transformation.parameters.forEach((parameter) => {
              const existingValue = 'value' in parameter;
              if (!existingValue || (parameter.name !== 'SENAO' && parameter.value === '')) {
                if (parameter.name !== 'Campo' && (transformation.template !== 'replace_all' && parameter.order !== 3)) {
                  this.parametersValueEmpty.push({
                    transformation: transformation.name,
                    parameter: parameter.name,
                    column: column.name,
                  });
                }
              }
              if (parameter.name === 'Campo') {
                // eslint-disable-next-line no-param-reassign
                parameter.name = column.name;
              }
              if (transformation.template === 'replace_all' && parameter.order === 3) {
                // eslint-disable-next-line no-param-reassign
                parameter.value = parameter.value ? parameter.value : '';
              }
            });
          } else {
            transformation.parameters.push({
              isAlphanumeric: false,
              isField: true,
              order: 1,
              name: column.name,
              value: column.name,
            });
          }
        });
      });

      if (this.transformationsOrderEmpty.length === 0 && this.parametersValueEmpty.length === 0) {
        this.allLayoutColumns = lodash.cloneDeep(allLayoutColumnsAux);
        this.payloadSubmited();
      } else {
        this.$refs.DynamicDialogInformation.open();
        this.overlay = false;
      }
    },
    payloadSubmited() {
      const layout = lodash.cloneDeep(this.layout);
      layout.columns = this.allLayoutColumns;
      if (this.isEdit) {
        this.isEditLayoutModel(layout);
      } else {
        this.isSaveAndCopyLayoutModel(layout);
      }
    },
    isSaveAndCopyLayoutModel(layout) {
      if (this.isCopy) {
        delete layout.id;
 
        layout.columns.forEach(el => {
          delete el.id;
          delete el.idLayout;
        });
      } else {
        layout.columns.forEach(column => {
          if (column.id && column.id.includes("new_column")) {
            column.id = null;
          }
        })
      }
      layout.entityType = null;

      this.layoutService.Save(layout).then(() => {
        this.overlay = false;
        this.$refs.SnackbarCustomize.open('success', 'Modelo cadastrado com sucesso!');
        setTimeout(() => {
          this.redirectRouter('confirm');
        }, 250);
      }).catch(() => {
        this.overlay = false;
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao criar o modelo de layout, tente novamente!');
      });
    },
    isEditLayoutModel(layout) {
      this.layoutService.Update(layout.id, layout).then(() => {
        this.overlay = false;
        this.$refs.SnackbarCustomize.open('success', 'Modelo editado com sucesso!');
        setTimeout(() => {
          this.redirectRouter('confirm');
        }, 250);
      }).catch(() => {
        this.overlay = false;
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao editar o modelo de layout, tente novamente!');
      });
    },
    overlayControll(data) {
      this.overlay = data;
    },
    redirectRouter(type) {
      if (type === 'confirm') {
        if (sessionStorage.getItem('redirectToImport')) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutImportConfigs' });
        } else {
          this.$router.push({ name: 'LayoutModel' });
        }
      } else if (type === 'back') {
        if (this.isEdit) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'ColumnEditConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'ColumnEditConfigs' });
        } else if (this.isCopy) {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'ColumnCopyConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'ColumnCopyConfigs' });
        } else {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'ColumnConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'ColumnConfigs' });
        }
      } else if (type === 'cancel') {
        if (sessionStorage.getItem('redirectToImport') || sessionStorage.getItem('visualization')) {
          sessionStorage.removeItem('visualization');
          sessionStorage.removeItem('layout');
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutImportConfigs', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutImportConfigs' });
        } else {
          this.$router.push(this.$route.query.financialGroupId ? { name: 'LayoutModel', query: { financialGroupId: Number(this.$route.query.financialGroupId) } } : { name: 'LayoutModel' });
        }
      }
    },
    scrollTo() {
      this.$refs.top.scrollIntoView({ behavior: 'smooth' });
    },
    scrollComponent(data) {
      if (data) {
        this.scrollTo();
      }
    },
  },

  created() {
    this.rule = new Rules();
    this.layoutService = new LayoutService();
    this.dataSourceService = new DataSourceService();
  },

});
</script>

<style scoped>
.visualization-text {
  color: #797787;
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0 1rem;
}
</style>

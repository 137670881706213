import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
export default {
  data: () => ({
    documentTypes: [],
  }),

  mixins: [
    GenericFunctionsMixin,
  ],

  methods: {
    async loadDocumentTypes() {
      const documentTypeService = new DocumentTypeService();
      const params = this.buildSearchParams({}, 1, 999);

      await documentTypeService.FindAllByFilter(params)
        .then((response) => response.data.content)
        .then((result) => this.documentTypes = result)
    },
  }
}
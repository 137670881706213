<template>
  <v-container
    fluid>
    <v-data-table
      v-model="localModel"
      v-bind="tableConfig"
      v-on="tableConfig.on"
      :page.sync="page"
      :items="items"
      :items-per-page="itemsPerPage"
      :loading="loading"
      :hide-default-footer="items.length === 0 || tableConfig.hideDefaultFooter"
      :loading-text="loadingMessage"
      :server-items-length="totalPages"
      class="elevation-1"
      :height="!!items && items.length >= 10 ? 528 : null"
      @update:options="changePage($event)"
      :footer-props="{
        itemsPerPageOptions: itemsPerPageOptions,
        disablePagination: loading,
      }">
      <template v-slot:no-data>
        <slot name="no-data"></slot>
      </template>

      <template v-for="header in tableConfig.headers" v-slot:[`item.${header.value}`]="slotProps">
        <slot :name="`item.${header.value}`" v-bind="slotProps"></slot>
      </template>
    </v-data-table>
</v-container>
</template>

<script>
export default {
  name: "DefaultTable",

  data: () => ({
    page: 1,
    itemsPerPage: 25,
    localModel: [],
    sort: null,
  }),

  props: {
    tableConfig: {
      type: Object,
      required: true,
    },
    model: {
      type: Array,
      required: false,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [ 25, 50, 75, 100 ],
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "Carregando..."
    },
    totalPages: {
      type: Number,
      required: true,
    }
  },

  watch: {
    localModel: {
      immediate: true,
      handler(newVal) {
        this.$emit('updateModel', [...newVal]);
      },
    },

    itemsPerPage: {
      immediate: true,
      handler(newVal) {
        this.$emit('itemsPerPage', newVal);
      }
    },

    page: {
      immediate: true,
      handler(newVal) {
        this.$emit('page', newVal);
      }
    },

    sort: {
      immediate: true,
      handler(newVal) {
        this.$emit('sort', newVal);
      }
    },
  },

  methods: {
    changePage(event) {
      const { page: newPage, itemsPerPage, sortBy, sortDesc } = event;
      this.page = newPage;
      this.itemsPerPage = itemsPerPage;
      this.sort = `${sortBy[0] ? sortBy[0] : 'id'},${sortDesc[0] ? 'asc' : 'desc'}`;

      setTimeout(() => this.$emit('search'), 200);
    },
  },
}
</script>
<template>
  <v-dialog
    :max-width="defaultWidth"
    v-model="modal">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg d-flex flex-column"
        style="min-height: 300px;">
        <v-card-title
          class="pt-9 px-12">
          <v-row
            class="text-center d-flex justify-start">
            <span
              :class="`text-h6 font-weight-bold ${defaultColor}--text`">
              {{ title }}
            </span>

            <v-spacer />

            <v-icon
              size="25"
              color="grey"
              @click="close">
              close
            </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text
          class="px-9 d-flex flex-column flex-grow-1">
          <slot name="section"></slot>
        </v-card-text>

        <v-card-actions
          class="px-9 pb-9">
          <v-row
            no-gutters>
            <v-spacer />
            <v-btn
              :class="`text-body-2 elevation-0 ${defaultColor}--text`"
              height="40"
              color="#EDEFF1"
              @click="close">
              <v-icon
                left>
                fas fa-arrow-left
              </v-icon>
              Fechar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>

export default {
  name: "DefaultModal",

  data: () => ({
    modal: false,
  }),

  props: {
    title: {
      type: String,
      required: true
    },
    defaultColor: {
      type: String,
      default: "primary",
    },
    defaultWidth: {
      required: false,
      default: "500"
    }
  },

  methods: {
    open() {
      this.modal = true;
    },
    close() {
      this.modal = false;
    }
  },
}
</script>
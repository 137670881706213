<template>
  <v-container fluid class="ma-4">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Configuração geração de lote de envio para operadora</h1>
      </v-col>
    </v-row>
    <v-row class="align-center mt-10">
      <v-col cols="12" xl="2" lg="2" md="3" sm="12" xs="12">
        <label class="label">Operadora</label>
        <v-autocomplete
          :items="insuranceCarriers"
          :disabled="loadingInsuranceCarriers"
          :loading="loadingInsuranceCarriers"
          v-model="selectedInsuranceCarrier"
          @change="loadLayoutListByInsuranceCarrierId('filter')"
          item-text="xipp_commercial_name"
          item-value="id"
          class="mt-2"
          placeholder="Selecione a operadora"
          outlined
          color="textPrimary"
        />
      </v-col>
      <v-spacer />
      <v-col cols="12" xl="2" lg="2" md="3" sm="12" xs="12" class="justify-end align-end d-flex mr-5">
        <v-btn
          class="white--text"
          x-large
          block
          color="textPrimary"
          @click="setBatchConfig('insertBatchConfig')"
          :disabled="!selectedInsuranceCarrier"
        >
          Nova Configuração
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12 pr-8">
        <DefaultTable
          ref="batchTable"
          :items="batchList"
          :tableConfig="tableConfig"
          :loading="isLoadingTable"
          :totalPages="totalPages"
          @itemsPerPage="itemsPerPage = $event"
          @page="page = $event"
          @sort="sort = $event"
          @search="search">
          <template v-slot:no-data>
            <span>Nenhum dado encontrado.</span>
          </template>
          <template v-slot:[`item.insuranceCarrierId`]="{ item }">
            <span>{{ translateInsuranceCarrierBy(item.insuranceCarrierId) }}</span>
          </template>
          <template v-slot:[`item.benefitType`]="{ item }">
            <span>{{ item.benefitType ? formatBenefitType(item.benefitType) : '-' }}</span>
          </template>
          <template v-slot:[`item.movementTypes`]="{ item }">
            <span>{{ formatMovementTypes(item.movementTypes) }}</span>
          </template>
          <template v-slot:[`item.layout`]="{ item }">
            <span>{{ item.layout && item.layout.name ? item.layout.name : '-' }}</span>
          </template>
          <template v-slot:[`item.generateBy`]="{ item }">
            <span>{{ translateGenerateBy(item.generateBy) }}</span>
          </template>
          <template v-slot:[`item.attachments`]="{ item }">
            <v-menu open-on-click bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                  <v-icon color="textPrimary" size="20">fas fa-ellipsis-v</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="setBatchConfig('edit', item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon size="20" color="textPrimary">fas fa-edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="mt-1">
                    <v-list-item-title>Editar Lote</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="openDeleteBatchDialog(item)">
                  <v-list-item-icon class="mr-2">
                    <v-icon size="20" color="textPrimary">fas fa-trash-alt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="mt-1">
                    <v-list-item-title>Deletar Lote</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </DefaultTable>

        <div class="float-right pt-2">
          <v-pagination
            v-model="page"
            color="textPrimary"
            :length="totalPages"
            :disabled="isLoadingTable"
            :total-visible="9"
            @input="loadLayoutListByInsuranceCarrierId('pagination')"
          />
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="deleteBatchDialog" max-width="600px">
        <v-card class="pa-5">
          <v-card-title class="text-h5 justify-center primary--text">Você tem certeza que deseja excluir esse lote?</v-card-title>
          <v-card-actions>
          <v-row class="mt-6">
            <v-spacer></v-spacer>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-btn
                color="#3B495B"
                outlined
                block
                @click="closeDeleteBatch()"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <v-btn
                color="#3B495B"
                block
                style="color: white"
                @click="confirmationDeleteBatch()"
              >
                Confirmar
              </v-btn>
            </v-col>
          </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar.show"
        timeout="10000"
        top
        elevation="24"
        :color="snackbar.type"
      >
        <div class="text-center">{{ snackbar.text }}</div>
        <template v-slot:action="{ attrs }">
          <v-icon
            text
            dark
            v-bind="attrs"
            @click="snackbar.show = false"
          >
            close
          </v-icon>
        </template>
      </v-snackbar>
    </v-row>

    <v-row>
      <v-col cols="12" align="end">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              class="mr-4"
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="onClickCancel()"
            >
              Voltar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>
    </v-row>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import InsuranceCarrierBatchConfigService from '@/services-http/sdi/InsuranceCarrierBatchConfigService';
import InsuranceCarrierPeriodicityService from '@/services-http/sdi/InsuranceCarrierPeriodicityService';
import BenefitService from '@/services-http/odoo/BenefitService';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import Formatters from '@/shared/formatters/formatters';
import { mapMutations, mapGetters } from 'vuex';
import DefaultTable from '@/components/Default/DefaultTable/DefaultTable.vue';

export default {
  components: { SnackbarCustomize, DefaultTable },

  data: () => ({
    insuranceCarriers: [],
    selectedInsuranceCarrier: 0,
    batchList: [],
    batchDeleteId: 0,
    page: 1,
    totalPages: 0,
    itemsPerPage: 25,
    sort: "id,desc",
    loadingInsuranceCarriers: false,
    batchDataProps: {},
    batchTypeManage: '',
    deleteBatchDialog: false,
    isLoadingTable: false,
    periodicity: {},
    benefitTypes: [],
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    tableConfig: {
      itemKey: 'id',
      itemsPerPage: 10,
      calculateWidths: true,
      hideDefaultFooter: true,
      headers: [
        {
          text: 'Operadora',
          value: 'insuranceCarrierId',
          align: 'center',
          sortable: false,
          class: 'text--truncate',
        },
        {
          text: 'Tipo de Benefício',
          value: 'benefitType',
          align: 'center',
          sortable: false,
          class: 'text--truncate',
        },
        {
          text: 'Tipo de movimentação',
          value: 'movementTypes',
          align: 'center',
          sortable: false,
          class: 'text--truncate',
        },
        {
          text: 'Nº mínimo de vidas',
          value: 'minLife',
          align: 'center',
          sortable: false,
          class: 'text--truncate',
        },
        {
          text: 'Layout',
          value: 'layout',
          align: 'center',
          sortable: false,
          class: 'text--truncate',
        },
        {
          text: 'Granularidade',
          value: 'generateBy',
          align: 'center',
          sortable: false,
          class: 'text--truncate',
        },
        {
          text: 'Ações',
          value: 'attachments',
          align: 'center',
          sortable: false,
          class: 'text--truncate',
        },
      ],
    }
  }),

  mixins: [
    InsuranceCarriersMixin,
  ],

  async mounted() {
    await this.getInsuranceCarriers();
    await this.loadBenetiType();

    if (this.batchInsuranceCarrierId) {
      this.selectedInsuranceCarrier = Number(this.batchInsuranceCarrierId);
      this.loadLayoutListByInsuranceCarrierId('filter');
    }
  },

  methods: {
    ...mapMutations({
      setInsuranceCarrierId: 'batch/setInsuranceCarrierId',
      cleanStore: 'batch/cleanStore',
    }),

    setBatchConfig(type, item) {
      if (type === 'insertBatchConfig') {
        this.$router.push({ name: 'BatchConfigNew', query: { insuranceCarrierId: this.selectedInsuranceCarrier } });
      } else if (type === 'edit') {
        this.$router.push({ name: 'BatchConfigEdit', params: { id: item.id }, query: { insuranceCarrierId: item.insuranceCarrierId } });
        // sessionStorage.setItem('InsuranceCarrierId', JSON.stringify(item.insuranceCarrierId));
        this.setInsuranceCarrierId(JSON.stringify(item.insuranceCarrierId))
      }
    },
    async loadLayoutListByInsuranceCarrierId(type) {
      if (this.selectedInsuranceCarrier && this.selectedInsuranceCarrier > 0) {
        this.isLoadingTable = true;
        this.periodicity = {};
        if (type === 'filter') this.page = 1;
        // sessionStorage.setItem('InsuranceCarrierId', JSON.stringify(this.selectedInsuranceCarrier));
        this.setInsuranceCarrierId(JSON.stringify(this.selectedInsuranceCarrier))
        this.loadCronExpressionByInsuranceCarrierId();
        this.insuranceCarrierBatchConfigService.FindAllByFilter({ insuranceCarrierId: this.selectedInsuranceCarrier, page: this.page - 1, size: 10, sort: 'id,desc' }).then((response) => {
          if (response && response.data) {
            this.batchList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.isLoadingTable = false;
          }
        }).catch(() => {
          this.isLoadingTable = false;
        });
      } else {
        this.setSnackbarCustomize('error', 'Selecione uma operadora para buscar os lotes referente a ela.');
      }
    },
    formatMovementTypes(item) {
      const movementType = [];
      item.forEach((element) => {
        movementType.push(` ${element.name}`);
      });
      return this.formatter.formatToTitleCase(movementType.toString());
    },
    loadCronExpressionByInsuranceCarrierId() {
      this.insuranceCarrierPeriodicityService.FindByInsuranceCarrierId(this.selectedInsuranceCarrier).then((response) => {
        if (response.data) {
          this.periodicity = response.data;
        }
      });
    },
    translateGenerateBy(item) {
      let text = '';
      if (item === 'FINANCIAL_GROUP') {
        text = 'Grupo Financeiro';
      }
      if (item === 'CONTRACT') {
        text = 'Contrato';
      }
      if (item === 'SUBCONTRACT') {
        text = 'Sub-Contrato';
      }
      if (item === 'INSURANCE_CARRIER') {
        text = 'Operadora';
      }
      return text;
    },
    translateInsuranceCarrierBy(item) {
      let text = '';
      this.insuranceCarriers.forEach((element) => {
        if (element.id === item) {
          text = element.xipp_commercial_name;
        }
      });
      return text;
    },
    openDeleteBatchDialog(item) {
      this.deleteBatchDialog = true;
      this.batchDeleteId = item.id;
    },
    async confirmationDeleteBatch() {
      await this.insuranceCarrierBatchConfigService.DeleteBatch(this.batchDeleteId).then(() => {
        this.deleteBatchDialog = false;
        this.loadLayoutListByInsuranceCarrierId('filter');
        this.setSnackbarCustomize('success', 'Configuração Deletada com sucesso');
      }).catch((error) => {
        this.deleteBatchDialog = false;
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao deletar a configuração');
      });
    },
    closeDeleteBatch() {
      this.deleteBatchDialog = false;
    },
    reloadTable(insuranceCarrierId) {
      this.selectedInsuranceCarrier = Number(insuranceCarrierId);
      this.loadLayoutListByInsuranceCarrierId('filter');
    },
    onClickCancel() {
      this.cleanStore();
      this.$router.push({ name: 'HomeAllMenus' });
    },
    async loadBenetiType() {
      this.benefitService.FindAll().then((response) => {
        if (response && response.data && response.data.records.length) {
          this.benefitTypes = response.data.records.filter((benefit) => benefit.xipp_contract_record_type_code !== null);
        }
      });
    },
    formatBenefitType(benefitType) {
      let benefitName = '-';
      if (this.benefitTypes && this.benefitTypes.length > 0) {
        const benefitFound = this.benefitTypes.find((benefit) => benefit.xipp_contract_record_type_code === benefitType);
        benefitName = benefitFound && benefitFound.name ? benefitFound.name : '-';
      }
      return benefitName;
    },
    setSnackbarCustomize(type, message) {
      this.$refs.SnackbarCustomize.open(type, message);
    },
  },

  computed: {
    ...mapGetters({
      batchInsuranceCarrierId: 'batch/batchInsuranceCarrierId'
    })
  },

  created() {
    this.insuranceCarrierBatchConfigService = new InsuranceCarrierBatchConfigService();
    this.insuranceCarrierPeriodicityService = new InsuranceCarrierPeriodicityService();
    this.benefitService = new BenefitService();
    this.formatter = new Formatters();
  },
};
</script>

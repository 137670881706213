var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',{class:{
    'advancedSearch rounded-pill fill-height': true,
    'hideResult': Array.isArray(_vm.result) && _vm.result.length > 0
  },attrs:{"items":_vm.items,"placeholder":"Busca de layout...","append-icon":"mdi-magnify","return-object":"","hide-details":"","dense":_vm.isDense,"no-filter":"","outlined":""},on:{"update:search-input":_vm.updateText},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(item)?_c('div',{staticClass:"wineLabel--text fill-height d-flex align-center",staticStyle:{"width":"100%","height":"48px"},on:{"click":() => _vm.setResult(item)}},[_c('span',[_vm._v(" Pesquisar por "),_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(item.label)}}),_vm._v(" para: "),_c('span',{domProps:{"textContent":_vm._s(_vm.text)}})])]):_vm._e()]}},{key:"selection",fn:function({ item, index }){return [(Array.isArray(_vm.result) && _vm.result.length > 0)?_c('div',{class:{
        'd-flex flex-nowrap fill-height overflow-auto': true,
        'pl-4': Array.isArray(_vm.result) && _vm.result.length === 0
      }},_vm._l((_vm.result),function(search,searchIndex){return _c('v-row',{key:search.uniqueKey,staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"fill-height d-flex flex-row align-center text-truncate elevation-3 mr-2"},[_c('div',{key:`${search.key}-${search.uniqueKey}`,class:`fill-height d-flex flex-row align-center ${_vm.color} white--text mr-2 px-4`},[_vm._v(" "+_vm._s(_vm.getItemLabel(search.key))+" ")]),_vm._l((search.value),function(value,valueIndex){return _c('span',{key:`${search.value}-${search.uniqueKey}-${valueIndex}`,staticClass:"fill-height d-flex flex-row align-center pr-2"},[_vm._v(" "+_vm._s(!!search.value && search.value.length > 0 && valueIndex < search.value.length-1 ? `${value} ou` : value)+" ")])}),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","outlined":"","small":""},on:{"click":function($event){return _vm.removeFilter(searchIndex)}}},[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v(" close ")])],1),_c('v-divider',{attrs:{"vertical":""}})],2)],1)}),1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  data: () => ({
    filterReportAutomation: {
      financialGroupId: '',
      dataModel: '',
      reportLayout: '',
      reportIntegrationType: '',
      receivingDateStart: '',
      receivingDateEnd: '',
      onlyActive: true
    },
    dataModelTypes: {
      'MOVEMENT_RECORD': 'Movimentação Cadastral',
      'FILE_DATA': 'Movimentação de Arquivo'
    },
    reportLayoutTypes: {
      'REPORT_MOVEMENT_RECORD_DEFAULT': 'Relatório de Movimentação Cadastral Padrão',
      'REPORT_MOVEMENT_RECORD_ANALYTICAL': 'Relatório de Movimentação Cadastral Analítico',
      'REPORT_FILE_DATA_ERRORS': 'Relatório de Erros',
      'REPORT_FILE_DATA_CORRECTION_FILE': 'Arquivo de Correção',
      'REPORT_FILE_DATA_CRITICISM_RETURN': 'Arquivo de Retorno de Críticas'
    },
    /*
      According to activity Xipp-7957, the IntegrationTypes field
      will initially have a fixed initial value as SFTP.

      Please remove the comment when FTP implementation is carried out.
    */
    integrationTypes: {
      // 'FTP': 'FTP',
      'SFTP': 'SFTP'
    },
    folderHierarchyTypes: {
      'YEAR_MONTH': 'AAAA/MM'
    }
  }),
  methods: {
    getDataModelType(type) {
      return this.dataModelTypes[type] || '-'
    },
    getReportLayoutType(type) {
      return this.reportLayoutTypes[type] || '-'
    },
    getIntegrationType(type) {
      return this.integrationTypes[type] || '-'
    },
    getReportLayoutTypesByDataModel(prop) {
      return this.getReportLayoutTypesFilter.filter(el => el.id.includes(prop));
    }
  },
  computed: {
    getDataModelTypes() {
      return Object.entries(this.dataModelTypes).map(
        ([id, name]) => ({ id, name }));
    },

    getReportLayoutTypesFilter() {
      return Object.entries(this.reportLayoutTypes).map(
        ([id, name]) => ({ id, name }));
    },

    getIntegrationTypes() {
      return Object.entries(this.integrationTypes).map(
        ([id, name]) => ({ id, name }));
    },
    getFolderHierarchyTypes() {
      return Object.entries(this.folderHierarchyTypes).map(
        ([id, name]) => ({ id, name }));
    }
  }
}

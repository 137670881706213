<template>
  <div v-if="verifyAuthority">
    <TitleRow title="Parâmetros Gerais" icon="icons/generalParameters.png" />
    <v-row class="mt-10" >
      <CustomMenu
        v-for="(menu, index) in generalParameters"
        :key="index"
        :icon="menu.icon"
        :title="menu.title"
        :color="menu.color"
        :authority="menu.authority"
        :paddingCustom="menu.paddingCustom"
        @click="redirectRouter(menu.routerName, { isRHProtegido: isRHProtegido})"
      />
    </v-row>
  </div>
</template>

<script>
import UserUtils from '@/shared/utils/user-utils';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import TitleRow from '@/components/Home/AllMenus/TitleRow.vue';
import CustomMenu from '@/components/Home/AllMenus/CustomMenu.vue';

export default ({
  components: { TitleRow, CustomMenu },
  data: () => ({
    hasNoAuthorities: false,
    isRHProtegido: false,
  }),

  mixins: [
    VerifyRoutesMixin,
  ],

  created() {
    this.userUtils = new UserUtils();
    this.emitVerifyAuthority();
  },

  computed: {
    generalParameters() {
      return [
        {
          icon: 'icons/rule.png',
          title: 'Regras Gerais',
          color: '#5ee6be',
          routerName: 'Rule',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_regras_gerais' }),
        },
        {
          icon: 'icons/movementRule.png',
          title: 'Regras de Movimentações',
          color: '#5ee6be',
          routerName: 'MovementRule',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_regras_movimentacao' }),
        },
        {
          icon: 'icons/eligibility.png',
          title: 'Elegibilidade',
          color: '#5ee6be',
          routerName: 'Eligibility',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_elegibilidade' }),
        },
        {
          icon: 'icons/parametrizationBrokerAndInsuranceCarrier.png',
          title: 'Parametrização dos tipos de Movimentações',
          color: '#5ee6be',
          routerName: 'ParametrizationBrokerAndInsuranceCarrier',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_parametrizacao_tipos_movimentacao' }),
        },
        {
          icon: 'icons/procedureRegisterSearch.png',
          title: 'Cadastro de Procedimento',
          color: '#5ee6be',
          routerName: 'ProcedureRegisterSearch',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_cadastro_procedimento' }),
        },
        {
          icon: 'icons/financialGroupSync.png',
          title: 'Sincronização de Grupo Financeiro',
          color: '#5ee6be',
          routerName: 'FinancialGroupSync',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_configuracao_implantacao_grupo_financeiro' }),
        },
        {
          icon: 'icons/insuranceCarrierSync.png',
          title: 'Sincronização de Operadora',
          color: '#5ee6be',
          routerName: 'InsuranceCarrierSync',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_configuracao_implantacao_operadora' }),
        },
        {
          icon: 'icons/insuranceCarrierCriticismManagement.png',
          title: 'Manutenção de Críticas da Operadora',
          color: '#5ee6be',
          routerName: 'InsuranceCarrierCriticismManagement',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_manutencao_criticas_operadora_visualizar' }),
        },
        {
          icon: 'icons/syncCache.svg',
          title: 'Sincronização de Cache',
          color: '#5ee6be',
          routerName: 'CacheSync',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_sincronizacao_cache' }),
        },
        {
          icon: 'icons/documentType.svg',
          title: 'Tipos de Documento',
          color: '#5ee6be',
          routerName: 'DocumentType',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_tipos_documento' }),
        },
        {
          icon: 'icons/movementDocument.svg',
          title: 'Documentos de Movimentação',
          color: '#5ee6be',
          routerName: 'MovementDocument',
          authority: this.userUtils.hasAuthorityToAccess({ authority: 'sdi_documento_movimentacao' }),
        },
      ];
    },
    verifyAuthority() {
      return this.generalParameters.some((menu) => menu.authority);
    },
  },

  methods: {
    emitVerifyAuthority() {
      this.$emit('verifyAuthorityGeneralParameters', this.verifyAuthority);
    },
  },
});
</script>

/* eslint-disable */
import HttpService from '../HttpService'

export default class DocumentTypeService {
  constructor() {
    this._httpService = new HttpService('/sdi/document-type');
  }

  async FindAllDocumentType(filter) {
    var queryString = new URLSearchParams(filter);
    return await this._httpService.get(`?${queryString}`);
  }

  async FindAllByFilter(params) {
    var queryString = new URLSearchParams(params);
    return await this._httpService.get(`?${queryString}`);
  }

  async Post(payload) {
    return await this._httpService.post('', payload);
  }

  async Put(payload) {
    return await this._httpService.put('', payload);
  }
};

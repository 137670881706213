<template>
  <v-container fluid class="ma-4 pr-10">
    <v-row>
      <v-col>
        <h1 class="secondary--text">Movimentação Cadastral</h1>
        <h2 class="secondary--text font-weight-regular">Exclusão</h2>
      </v-col>
    </v-row>
    <h2 class="mt-6 mb-2 secondary--text font-weight-regular">
      Pesquisa Beneficiário
    </h2>
    <v-row>
      <v-col cols="12" xs="6" sm="6" md="1" lg="1">
        <v-btn
          class="white--text"
          :outlined="search.type === 'dependent'"
          medium
          block
          color="textPrimary"
          @click="search.type = 'holder'; results = {}"
        >
          Titular
        </v-btn>
      </v-col>
      <v-col cols="12" xs="6" sm="6" md="1" lg="1">
        <v-btn
          class="white--text"
          :outlined="search.type === 'holder'"
          medium
          block
          color="textPrimary"
          @click="search.type = 'dependent'; results = {}"
        >
          Dependente
        </v-btn>
      </v-col>
    </v-row>
    <v-form ref="formRegister" v-model="isValidFormSearch" lazy-validation>
      <v-row class="mt-10 align-center">
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Grupo Financeiro</label>
          <v-autocomplete
            v-model="search.financialGroupIds"
            :items="financialGroups"
            item-text="name"
            item-value="id"
            :loading="loadingFinancialGroups"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierIds ? true : false"
            @input="getContractsByFinancialGroupIdVerifyRhProtegido(search.financialGroupIds, isRHProtegido); verifyFieldsFilters()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Operadora</label>
          <v-autocomplete
            v-model="search.insuranceCarrierIds"
            :items="insuranceCarriers"
            item-text="xipp_commercial_name"
            item-value="id"
            :loading="loadingInsuranceCarriers"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.financialGroupIds ? true : false"
            @input="verifyFieldsFilters(); verifyFreeMovement()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Contrato</label>
          <v-autocomplete
            v-model="search.contractIds"
            :items="contracts"
            item-value="id"
            :loading="loadingContracts"
            class="mt-2"
            placeholder="Selecione"
            outlined
            color="textPrimary"
            :disabled="search.insuranceCarrierIds ? true : false"
            @input="verifyFieldsFilters(); verifyFreeMovement()"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="11" md="3" lg="3" xl="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            :disabled="isDisableFreeMovement"
            @click.prevent="onClickDeleteFreeMovement()"
          >
            Movimentação livre
          </v-btn>
        </v-col>
        <v-col class="pt-0 pl-0 pb-6" cols="12" xs="12" sm="1" md="3" lg="2">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                dense
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <span>Movimentação livre: escolha um contrato que permita movimentação livre</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="align-center" lazy-validation>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">{{ search.type === 'holder' ? 'CPF do Titular' : 'CPF do Dependente' }}</label>
          <v-text-field
            v-model="search.cpf"
            class="mt-2"
            placeholder="Informe o número"
            v-mask="'###.###.###-##'"
            :rules="search.cpf ? [rule.cpf] : []"
            outlined
            required
            color="textPrimary"
            @input="verifyFieldsFilters()"
            @keyup.enter="filter('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">{{ search.type === 'holder' ? 'Nome do Titular' : 'Nome do Dependente' }}</label>
          <v-text-field
            v-model.trim="search.name"
            :rules="search.name ? [rule.name, rule.validateIsAlphanumeric] : []"
            class="mt-2"
            placeholder="Informe o nome"
            maxlength="70"
            outlined
            color="textPrimary"
            required
            @input="verifyFieldsFilters();"
            @keyup.enter="filter('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <label class="label">Matrícula</label>
          <v-text-field
            maxlength="50"
            v-model="search.registration"
            class="mt-2"
            placeholder="Informe a matrícula"
            outlined
            color="textPrimary"
            @input="verifyFieldsFilters()"
            @keyup.enter="filter('filter')"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="2">
          <v-btn
            class="white--text"
            x-large
            block
            color="textPrimary"
            :disabled="isDisableFieldFilters"
            :loading="loadingBeneficiary"
            @click.prevent="filter('filter')"
          >
            Localizar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="2">
          <v-btn color="textPrimary" x-large text @click="clearFields()">
            Limpar campos
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <HolderSearchTable
        v-if="search.type === 'holder'"
        :responseData="results"
        :loadingBeneficiary="loadingBeneficiary"
        movementType="delete"
        @setPagination="setPagination"
        @onClickHandler="onClickDelete"
      />
      <DependentSearchTable
        v-else
        :responseData="results"
        :byAcordeon="false"
        :loadingBeneficiary="loadingBeneficiary"
        movementType="delete"
        @setPagination="setPagination"
        @onClickHandler="onClickDelete"
      />
    </v-row>

    <v-footer class="my-10 pr-0">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn x-large block outlined color="textPrimary" @click="onClickCancel()">
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-snackbar v-model="snackbar.show" timeout="10000" top elevation="24" :color="snackbar.type">
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon text dark v-bind="attrs" @click="snackbar.show = false">
          close
        </v-icon>
      </template>
    </v-snackbar>

    <DynamicDialogInformation ref="DynamicDialogInformation" :widthModalValue="widthModal" :heightCardValue="heightCard">
      <v-row class="mb-3" justify="center" slot="textInfo" style="word-break: break-word">
        <v-col cols="12" align="center">
          <label class="label primary--text font-italic" style="word-break: break-word">“Ops! Algo deu errado. Isso pode ter acontecido por você ter selecionado um grande volume de informações. Tente refinar os filtros e repetir a operação”</label>
        </v-col>
      </v-row>
    </DynamicDialogInformation>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import DependentContractedPlanService from '@/services-http/contract/DependentContractedPlanService';
import DynamicDialogInformation from '@/components/DynamicDialogInformation/DynamicDialogInformation.vue';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import ContractService from '@/services-http/contract/ContractService';
import HolderSearchTable from '@/components/Beneficiary/Holder/HolderSearchTable.vue';
import DependentSearchTable from '@/components/Beneficiary/Dependent/DependentSearchTable.vue';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ManualMovementSearchHolderDependent',
  components: { DynamicDialogInformation, HolderSearchTable, DependentSearchTable },
  data: () => ({
    overlay: false,
    search: {
      financialGroupIds: null,
      insuranceCarrierIds: null,
      contractIds: null,
      registration: null,
      cpf: null,
      name: null,
      type: 'holder',
    },
    loadingBeneficiary: false,
    results: {},
    expanded: [],
    financialGroups: [],
    insuranceCarriers: [],
    contracts: [],
    page: 1,
    totalPages: 0,
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    isValidFormSearch: true,
    isRHProtegido: false,
    loadingFinancialGroups: false,
    loadingInsuranceCarriers: false,
    loadingContracts: false,
    isDisableFieldFilters: true,
    isDisableFreeMovement: true,
    widthModal: 800,
    heightCard: 600,
    beneficiaryData: {},
    insuranceCarrierId: null,
  }),

  mixins: [
    FinancialGroupsMixin,
    ContractsMixin,
    InsuranceCarriersMixin,
    VerifyRoutesMixin,
    GenericFunctionsMixin,
  ],

  async mounted() {
    window.addEventListener('beforeunload', this.handlePageRefresh);
    this.setSessionStoreData()
    this.clearSessionStorage();
    if (this.$router.currentRoute.params.creation) {
      this.makePreviewSearch();
    }
    await this.getFinancialGroups();
    if (this.financialGroups.length === 1) {
      this.search.financialGroupIds = this.financialGroups[0].id;
      this.verifyFieldsFilters();
      await this.getContractsByFinancialGroupIdVerifyRhProtegido(this.search.financialGroupIds, this.isRHProtegido);
    }
    await this.getInsuranceCarriers();
  },

  methods: {
    ...mapMutations({
      setSearchParams: 'handler/setSearchParams',
    }),
    async makePreviewSearch() {
      if (Object.keys(this.searchParams.search).length === 0) return;

      this.isDisableFieldFilters = false;

      this.search = {
        ...this.searchParams.search,
      };

      if (this.search && !!this.search.contractIds) {
        this.getContractsByFinancialGroupIdVerifyRhProtegido(
          this.search.financialGroupIds,
          this.isRHProtegido
        )
      }

      this.filter();
    },
    handlePageRefresh() {
      if (Object.keys(this.searchParams) > 0) {
        sessionStorage.setItem(
          'searchParams',
          JSON.stringify(this.searchParams)
        );
      }
    },
    setSessionStoreData() {
      if (sessionStorage.getItem('searchParams')) {
        this.setSearchParams(
          JSON.parse(sessionStorage.getItem('searchParams'))
        );

        sessionStorage.removeItem('searchParams');
      }
    },
    setPagination(pagination) {
      this.page = pagination.page;
      this.totalPages = pagination.totalPages;
      this.filter('pagination');
    },
    filter(type) {
      if (this.$refs.formRegister.validate()) {
        this.results.content = [];
        if (type === 'filter') this.page = 1;
        if (this.search.financialGroupIds !== null || this.search.insuranceCarrierIds !== null || this.search.contractIds !== null || this.search.registration !== null || this.search.cpf !== null || this.search.name !== null) {
          const data = this.verifyParams();

          this.setSearchParams({ search: this.search, page: this.page });

          if (this.search.type === 'holder') {
            this.loadBeneficiary(data);
          } else if (this.search.type === 'dependent') {
            this.loadDependent(data);
          }
        }
      } else {
        this.customizeSnackbarMessage('error', 'Preencha corretamente para prosseguir');
      }
    },
    verifyParams() {
      const cpfUnmasked = this.search.cpf ? this.formatter.removeNonDigit(this.search.cpf) : null;
      const isActive = 'ACTIVE';
      const page = this.page - 1;
      const size = 10;

      const params = new URLSearchParams();
      if (this.search.financialGroupIds) {
        params.append('financialGroupIds', this.search.financialGroupIds);
      }
      if (this.search.insuranceCarrierIds) {
        params.append('insuranceCarrierIds', this.search.insuranceCarrierIds);
      }
      if (this.search.contractIds) {
        params.append('contractIds', this.search.contractIds);
      }
      if (this.search.registration) {
        params.append('registration', this.search.registration);
      }
      if (this.search.cpf) {
        params.append('cpf', cpfUnmasked);
      }
      if (this.search.name) {
        params.append('name', this.search.name);
      }

      if (this.isRHProtegido) {
        params.append('allowMovementByProtectedHR', 'true');
      }

      params.append('insuredSituation', isActive);
      params.append('page', page);
      params.append('size', size);

      return params;
    },
    clearFields() {
      this.$refs.formRegister.reset();
      this.contracts = [];
      this.isDisableFreeMovement = true;
    },
    async onClickDeleteFreeMovement() {
      this.overlay = true;
      this.beneficiaryData = {
        freeMovement: true,
        contractId: String(this.search.contractIds),
        financialGroupId: String(this.search.financialGroupIds),
        isHolder: this.search.type === 'holder',
        isDependent: this.search.type !== 'holder',
        contractedPlanId: null,
      };
      await this.requestInsuranceCarrier(this.search.contractIds);
      sessionStorage.setItem('beneficiaryData', JSON.stringify(this.beneficiaryData));
      const name = this.beneficiaryData.isHolder ? 'HolderDelete' : 'DependentDelete';
      const query = {
        isRHProtegido: this.isRHProtegido,
        freeMovement: this.beneficiaryData.freeMovement,
        insuranceCarrierId: this.insuranceCarrierId,
      };

      this.overlay = false;
      this.redirectRouter(name, query);
    },
    async onClickDelete(item) {
      this.overlay = true;

      if (item.isHolder) {
        await this.loadDependents(item)
      } else {
        await this.getDependentById(item);
      }

      await this.requestInsuranceCarrier(item.contractId);
      this.beneficiaryData = {
        ...item,
        freeMovement: false,
      };
      sessionStorage.setItem('beneficiaryData', JSON.stringify(this.beneficiaryData));
      const name = this.beneficiaryData.isHolder ? 'HolderDelete' : 'DependentDelete';
      const query = {
        isRHProtegido: this.isRHProtegido,
        freeMovement: this.beneficiaryData.freeMovement,
        insuranceCarrierId: this.insuranceCarrierId,
      };
      this.overlay = false;
      this.redirectRouter(name, query);
    },
    async requestInsuranceCarrier(contract) {
      if (contract) {
        await this.contractService.FindById(contract).then(async (response) => {
          this.insuranceCarrierId = response.data.carrier_id.id;
        }).catch(() => {
          this.overlay = false;
        });
      }
    },
    async loadBeneficiary(params) {
      this.loadingBeneficiary = true;
      await this.beneficiaryContractedPlanService.HolderToCreateMovement(params).then((response) => {
        if (response && response.data) {
          this.loadingBeneficiary = false;
          this.results = response.data;
        }
      }).catch(() => {
        this.loadingBeneficiary = false;
        this.$refs.DynamicDialogInformation.open();
      });
    },
    async loadDependent(params) {
      this.loadingBeneficiary = true;
      await this.dependentContractedPlanService.DependentToCreateMovement(params).then((response) => {
        if (response && response.data) {
          this.loadingBeneficiary = false;
          this.results = response.data;
        }
      }).catch(() => {
        this.loadingBeneficiary = false;
        this.$refs.DynamicDialogInformation.open();
      });
    },
    async loadDependents(item) {
      const params = new URLSearchParams();

      if (item.financialGroupId) {
        params.append('financialGroupIds', item.financialGroupId);
      }

      if (item.contractId) {
        params.append('contractIds', item.contractId);
      }

      if (item.subcontractId) {
        params.append('subcontractIds', item.subcontractId);
      }

      item.dependents = [];

      await this.dependentContractedPlanService.DependentToCreateMovementByInsuredId(item.contractPlanId, params).then((response) => {
        if (response && response.data) {
          item.dependents = response.data;
        }
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao carregar os dependentes');
      });
    },
    async getDependentById(item) {
      const queryString = `v2/${item.contractPlanId}/contracted_plan_data`;

      await this.dependentContractedPlanService.FindById(queryString).then((response) => {
        if (response && response.data) {
          const dependent = response.data;
          item.holderName = dependent.parentName;
          item.holderCpf = dependent.parentPhysicalPersonCpf;
          item.holderBeneficiaryCard = dependent.parentBeneficiaryCard;
          item.parentContractPlanId = dependent.parentContractPlanId;
        }
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Erro ao carregar os dados do dependente');
        this.overlay = false;
      });
    },
    verifyFieldsFilters() {
      if (this.search.financialGroupIds || this.search.insuranceCarrierIds || this.search.contractIds || this.search.registration || this.search.cpf || this.search.name) {
        this.isDisableFieldFilters = false;
      } else {
        this.isDisableFieldFilters = true;
      }
    },
    onClickCancel() {
      this.setSearchParams({});
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'Movement';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    verifyFreeMovement() {
      if (this.contracts && this.contracts.length > 0 && this.search.contractIds) {
        this.isDisableFreeMovement = this.contracts.filter((contract) => contract.id === this.search.contractIds).some((isFreeMovement) => !isFreeMovement.allow_free_movement);
      } else {
        this.isDisableFreeMovement = true;
      }
    },
    constructorContractName(item) {
      let text = '';
      if (item) {
        text = `${item.benefitName ? item.benefitName : '-'} - ${item.contractName ? item.contractName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
    constructorSubContractName(item) {
      let text = '';
      if (item) {
        text = `${item.subcontractNumber ? item.subcontractNumber : '-'} - ${item.subcontractOwnerLegalName ? item.subcontractOwnerLegalName : '-'}`;
      } else {
        text = '-';
      }
      return text;
    },
  },
  computed: {
    ...mapGetters({
      searchParams: 'handler/searchParams',
    }),
  },
  async created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
    this.dependentContractedPlanService = new DependentContractedPlanService();
    this.contractService = new ContractService();
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handlePageRefresh);
  },
};
</script>

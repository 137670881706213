<template>
  <v-container fluid>
    <v-row class="mt-2" align="center">
      <v-col cols="12" ref="tops">
        <p class="title">Adicionar Dependente</p>
      </v-col>
    </v-row>
    <v-row align="start">
      <v-col cols="12">
        <p class="title" v-if="!this.isFreeMovement">
          Titular:
          <strong>{{ holderName ? holderName : '' }}</strong>
        </p>
      </v-col>
      <v-col cols="12 py-0">
        <v-form v-if="this.isFreeMovement" ref="formHolderInfo">
          <PrincipalInformations
            :holderInformationData="holder"
            :showHeaderPage="false"
            :isDetail="isFreeMovement ? false : true"
            :showAdmissionDate="false"
            :showCardNumber="true"
            :showRegistrationPlate="true"
            @observerHolderInformationData="observerHolderInformationData"
          />
        </v-form>
      </v-col>
      <v-col cols="12 pt-0">
        <v-form ref="formPlanInfo">
          <PlanInformation
            class="py-0"
            :contractedPlanData="contractedPlanData"
            :isDetail="isFreeMovement ? false : true"
            :showHeaderPage="false"
            :showEligibility="false"
            :showStartValidityDate="false"
            :showEndValidityDate="false"
            :showCancellationReason="false"
            :showFamilyCode="false"
            :showFamilyCodeSequential="false"
            @observerContractedPlan="observerContractedPlan"
          />
        </v-form>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12">
        <v-card class="px-6" elevation="1">
          <v-row>
            <v-col cols="12">
              <p class="title">Adicionar Dependente</p>
            </v-col>
          </v-row>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  v-model="dependent.dependentCpf"
                  label="CPF"
                  placeholder="Informe o cpf"
                  :rules="dependent.dependentCpf ? [rule.cpf] : []"
                  validate-on-blur
                  v-mask="'###.###.###-##'"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="4">
                <v-text-field
                  v-model.trim="dependent.dependentName"
                  label="Nome Completo"
                  maxlength="70"
                  placeholder="Informe o nome completo"
                  :rules="dependent.dependentName ? [rule.validateIsAlphanumeric, rule.name] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                  @blur="dependent.dependentName = formatter.formatToTitleCase(dependent.dependentName)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <div class="d-flex flex-column">
                  <v-text-field
                    outlined
                    label="Data de Nascimento"
                    v-model="dependent.birthDate"
                    append-icon="fas fa-calendar-alt"
                    placeholder="DD/MM/YYYY"
                    v-mask="'##/##/####'"
                    color="textPrimary"
                    :rules="dependent.birthDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                    validate-on-blur
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  v-model="dependent.stateBirth"
                  :items="states"
                  item-text="label"
                  item-value="label"
                  outlined
                  label="UF de Nascimento"
                  color="textPrimary"
                  item-color="textPrimary"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  :items="genders"
                  v-model="dependent.genderId"
                  item-text="description"
                  item-value="id"
                  clearable
                  label="Gênero"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="4">
                <v-text-field
                  v-model.trim="dependent.motherName"
                  label="Nome da Mãe"
                  maxlength="70"
                  placeholder="Informe o nome da mãe"
                  :rules="dependent.motherName ? [rule.name, rule.validateIsAlphanumeric] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                  @blur="dependent.motherName = formatter.formatToTitleCase(dependent.motherName)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-autocomplete
                  :items="kinships"
                  v-model="dependent.degreeKinshipId"
                  item-text="description"
                  item-value="id"
                  clearable
                  label="Grau de Parentesco"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  v-model="dependent.cns"
                  label="CNS"
                  placeholder="Informe o CNS"
                  v-mask="'###############'"
                  :rules="dependent.cns ? [rule.cns] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  ref="dnv"
                  v-model="dependent.dnv"
                  v-mask="'##-########-#'"
                  label="Declaração de Nascido Vivo (DNV)"
                  placeholder="Informe o DNV"
                  outlined
                  :rules="dependent.dnv ? [rule.dnv] : []"
                  validate-on-blur
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
               <v-text-field
                  label="Peso(kg)"
                  v-mask="'###'"
                  type="number"
                  min="1"
                  placeholder="Informe o peso"
                  v-model="dependent.weight"
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @input="dependent.weight = formatter.removeInvalidNumber(dependent.weight)"
                  @blur="validateNumbersGreaterThanOne(dependent.weight, 'fieldCheckedWeight')"
                  id="fieldCheckedWeight"
                  outlined
                  color="textPrimary"
                  :rules="controlWeight ? ['Favor informar um número válido'] : dependent.weight ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.weight)] : []"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  label="Altura(cm)"
                  v-mask="'###'"
                  type="number"
                  min="1"
                  placeholder="Informe a altura"
                  v-model="dependent.height"
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @input="dependent.height = formatter.removeInvalidNumber(dependent.height);"
                  @blur="validateNumbersGreaterThanOne(dependent.height, 'fieldCheckedHeight')"
                  id="fieldCheckedHeight"
                  outlined
                  color="textPrimary"
                  :rules="controlHeight ? ['Favor informar um número válido'] : dependent.height ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.height)] : []"
                />
              </v-col>
              <v-col v-if="conditionShowEventDate()" cols="12" sm="6" md="4" lg="3" xl="2">
                <div class="d-flex flex-column">
                  <v-text-field
                    outlined
                    label="Data do evento"
                    v-model="dependent.eventDate"
                    append-icon="fas fa-calendar-alt"
                    placeholder="DD/MM/YYYY"
                    v-mask="'##/##/####'"
                    color="textPrimary"
                    :rules="dependent.eventDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                    validate-on-blur
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" :xl="conditionShowEventDate() ? '3' : '2'">
                <div class="d-flex flex-column">
                  <v-menu
                    v-model="validityDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template
                      v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        @click="getAllowedDates()"
                        label="Data de Vigência (DD/MM/YYYY)"
                        v-model="dependent.validityDate"
                        append-icon="fas fa-calendar-alt"
                        placeholder="Informe a data de vigência do plano"
                        v-mask="'##/##/####'"
                        color="textPrimary"
                        :rules="dependent.validityDate ? [rule.isDateValid] : []"
                        validate-on-blur
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        clearable
                      />
                    </template>
                    <AllowedDatePicker
                      @date="updateValidityDate($event, dependent)"
                      :allowedDates="allowedDates"
                    />
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  :items="maritalStatus"
                  v-model="dependent.maritalStatusId"
                  label="Estado Civil"
                  item-text="description"
                  item-value="id"
                  outlined
                  color="textPrimary"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  outlined
                  v-model="dependent.familyCode"
                  label="Código familiar"
                  v-mask="`${ formatter.maskLimiter(10) }`"
                  color="textPrimary"
                  placeholder="Informe código familiar"
                  :disabled="isDisabledFamilyCode"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  outlined
                  v-model="dependent.familyCodeSequential"
                  label="Sequencial"
                  v-mask="`${ formatter.maskLimiter(5) }`"
                  color="textPrimary"
                  placeholder="Informe código sequencial"
                  :disabled="isDisabledFamilyCodeSequential"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  label="Complemento de Matrícula"
                  placeholder="Informe o complemento"
                  v-model="dependent.registrationComplement"
                  maxlength="50"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  label="Carência"
                  v-model="dependent.hasGracePeriod"
                  :items="graceItems"
                  :disabled="isRHProtegido"
                  placeholder="Carência"
                  outlined
                  color="textPrimary"
                  clearable
                />
              </v-col>
            </v-row>
            <v-col cols="12">
                <label class="title">Dados de Contato</label>
                <v-row class="my-5">
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Telefone Residencial"
                      placeholder="Informe o número"
                      v-model="dependent.telephone"
                      lazy-validation
                      v-mask="'(##) ####-####'"
                      :rules="dependent.telephone ? [rule.telephone] : []"
                      validate-on-blur
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Celular"
                      placeholder="Informe o número"
                      v-model="dependent.cellphone"
                      v-mask="'(##) #####-####'"
                      :rules="dependent.cellphone ? [rule.cellphone] : []"
                      validate-on-blur
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="E-Mail"
                      placeholder="exemplo@email.com.br"
                      :rules="dependent.email ? [rule.email] : []"
                      v-model="dependent.email"
                      outlined
                      color="textPrimary"
                      validate-on-blur
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <label class="title">Identidade</label>
                <v-row class="my-5">
                  <v-col cols="3">
                    <label class="label">Natureza</label>
                    <v-autocomplete
                      class="mt-2"
                      v-model="dependent.identityDocumentNature"
                      :items="identityDocumentNatureTypes"
                      @input="dependent.identityDocumentNumber = null; "
                      placeholder="Informe a natureza do documento"
                      color="textPrimary"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="3">
                    <label class="label">Documento</label>
                    <v-text-field
                      v-model="dependent.identityDocumentNumber"
                      :rules="dependent.identityDocumentNumber ? [rule.rgMaxDigit(dependent.identityDocumentNature, dependent.identityDocumentNumber)] : []"
                      validate-on-blur
                      v-mask="'NNNNNNNNNNNNNNN'"
                      class="mt-2"
                      placeholder="Informe o número do documento"
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                  <v-col cols="3">
                    <label class="label">Data de Expedição</label>
                    <div class="d-flex flex-column">
                      <v-text-field
                        outlined
                        v-model="dependent.identityDocumentEmissionDate"
                        append-icon="fas fa-calendar-alt"
                        placeholder="DD/MM/YYYY"
                        v-mask="'##/##/####'"
                        color="textPrimary"
                        class="mt-2"
                        :rules="dependent.identityDocumentEmissionDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                        validate-on-blur
                      />
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <label class="label">Orgão Emissor</label>
                    <v-text-field
                      maxlength="8"
                      v-mask="'AAAAAAAA'"
                      @input="dependent.identityDocumentIssuer = dependent.identityDocumentIssuer.toUpperCase();"
                      v-model="dependent.identityDocumentIssuer"
                      class="mt-2"
                      placeholder="Informe o órgão emissor"
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                </v-row>
                <v-row class="my-5">
                  <v-col cols="3">
                    <label class="label">UF Emissor</label>
                    <v-autocomplete
                      v-model="dependent.identityDocumentState"
                      :items="states"
                      class="mt-2"
                      item-text="label"
                      item-value="label"
                      outlined
                      placeholder="UF emissor"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      clearable
                    />
                  </v-col>
                  <v-col cols="3">
                    <label class="label">País Emissor</label>
                    <v-autocomplete
                      v-model="dependent.identityDocumentCountry"
                      :items="country"
                      class="mt-2"
                      item-text="text"
                      item-value="value"
                      outlined
                      placeholder="País emissor"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      clearable
                    />
                  </v-col>
              </v-row>
            </v-col>
            <div ref="addressInformationComponent">
              <AddressInformations
                class="pt-0"
                :addressData="dependent.address"
                :showHeaderPage="true"
                :isFreeMovement="false"
                :isDetail="true"
                @updateAddressData="updateAddressData"
              />
            </div>

            <v-row class="mb-5">
              <v-col v-if="contractedPlanData.planId && plans && plans.length > 0" cols="12" xs="12" sm="12" md="12" lg="12">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="4" lg="3">
                    <v-btn class="white--text" x-large block color="textPrimary" disabled>
                      {{ contractedPlanData.planId ? getPlanName(contractedPlanData.planId) : '' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="documentsList.length > 0"  align="center" class="pr-4">
              <v-col cols="12">
                <v-row class="mt-5 pr-4">
                  <v-col cols="12" md="6" align="start">
                    <p class="title">Upload de Documentos</p>
                  </v-col>
                </v-row>
                <v-row class="mb-3 ml-1" style="border-style: dashed; border-width: 2px; border-color: var(--v-primary-base);">
                  <v-col cols="12" md="5" v-for="(item, index) in documentsList" :key="index">
                    <label class="label"> {{ item.description }}  </label>
                    <v-row>
                      <v-col>
                        <v-file-input
                          v-model="item.file"
                          @change="uploadDocument(item)"
                          placeholder="Clique para adicionar"
                          accept="application/pdf, image/jpg, image/jpeg, image/png, image/bmp, image/tiff, image/webp"
                          prepend-inner-icon="mdi-paperclip"
                          prepend-icon=""
                          color="textPrimary"
                          outlined
                          :disabled="waitingDocumentUpload"
                          :messages="waitingDocumentUpload ? 'Enviando seu documento... Por favor, aguarde.' : ''"
                          :loading="waitingDocumentUpload"
                          :rules="item.file ? [rule.validateUploadFiles(item)] : []"
                          @click:clear="clearDocument(item)"
                        >
                          <template v-slot:selection="{}">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  small
                                  label
                                  color="primary"
                                  v-on="on"
                                >
                                  {{ truncateText(item.file.name, 25) }}
                                </v-chip>
                              </template>
                              <span>{{ item.file.name }}</span>
                            </v-tooltip>
                          </template>
                        </v-file-input>
                      </v-col>
                      <v-col class="mb-7" align-self="center">
                        <v-btn class="text--white" :loading="item.loadingDownload" color="primary" @click="downloadDocuments(item)">
                          Baixar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <div v-if="violations.length > 0" class="mt-10">
                <ViolationRulesAlert :violations="violations" />
              </div>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <v-footer>
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            v-if="isEdit && isCriticizedBroker && isCriticizedCarrier"
            :disabled="loading"
            x-large
            block
            text
            color="textPrimary"
            @click="onClickBack()"
          >
            Voltar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            :disabled="loading"
            x-large
            block
            outlined
            color="textPrimary"
            @click="onClickCancel()"
          >
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            :disabled="loading"
            :loading="loading"
            class="white--text"
            x-large
            block
            color="textPrimary"
            @click="onClickFinish()"
          >
            Concluir
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-dialog v-model="confirmReturnModal" persistent :max-width="600">
      <v-card class="overflow-y-auto pa-5">
        <v-card-title class="pa-5">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-icon large color="green">fa-check</v-icon>
              <v-row class="mb-3" justify="center" style="word-break: break-word">
                <v-col cols="12" align="center">
                  <label class="label primary&#45;&#45;text font-italic" style="word-break: break-word">
                    {{ isEdit ? 'Movimentação editada com sucesso!' : 'Movimentação cadastrada com sucesso!' }}
                  </label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-row class="ma-1 justify-center">
            <v-btn class="pa-7" color="#3B495B" style="color: white" @click="closeConfirmReturnModal()">
              IR PARA A LISTA DE MOVIMENTAÇÕES
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="alertGraceModal" persistent max-width="800">
      <v-card width="800" class="pa-3 rounded-lg">
        <v-card-title class="text-h5 font-weight-bold" style="color: #3b495b;">
          <v-row
            class="d-flex justify-space-between px-3 pt-3">
            <span>Incidência de Carência</span>
            <v-icon @click="closeAlertGraceModal()">
              fas fa-times
            </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form ref="formFilterExportMovement">
            <v-row justify="start">
              <v-col>
                <p>Os campos Data de Vigência, Data do Evento e Data de Nascimento são obrigatórios para as operadoras: {{[this.invalidGraceContractNames.slice(0, -1).join(', '), this.invalidGraceContractNames.slice(-1)[0]].join(this.invalidGraceContractNames.length < 2 ? '' : ' e ')}}</p>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions
          class="px-6 pb-3">
          <v-spacer />
          <v-btn color="primary" width="150px" outlined @click="closeAlertGraceModal()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="validateGraceModal" persistent max-width="800">
      <v-card width="800" class="pa-3 rounded-lg">
        <v-card-title class="text-h5 font-weight-bold" style="color: #3b495b;">
          <v-row
            class="d-flex justify-space-between px-3 pt-3">
            <span>Incidência de Carência</span>
            <v-icon @click="closeValidateGraceModal()">
              fas fa-times
            </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form ref="formFilterExportMovement">
            <v-row justify="start">
              <v-col>
                <p>A movimentação desejada excede o prazo máximo dias com relação à Data do Evento. Portanto, incidirá carência.</p>
                <div v-for="grace in gracePeriods">
                  <p v-if="grace.hasGracePeriod">
                    {{grace.benefitId}} - {{grace.policy}} - Acima do prazo máximo de {{grace.daysGracePeriod}} dias
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions
          class="px-6 pb-3">
          <v-spacer />
          <v-btn color="primary" width="150px" outlined @click="closeValidateGraceModal()">
            Cancelar
          </v-btn>
          <v-btn color="primary" width="150px" outlined @click="nonConfirmGracePeriod()">
            Atendimento
          </v-btn>
          <v-btn color="primary" width="150px" @click="confirmGracePeriod()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="120"
      >
        <div>
          Carregando...
        </div>
      </v-progress-circular>
    </v-overlay>

    <div v-if="isCriticizedCarrier || isCriticizedBroker">
      <ViewCriticizedViolationDialog />
    </div>

    <SnackbarCustomize ref="SnackbarCustomize" />

    <ViewCriticismAndInternalNotesModal
      ref="ViewCriticismAndInternalNotesModal"
      @setSnackbarCustomize="setSnackbarCustomize()"
      @confirmCorrectionMovement="insertEventualDependents"
    />
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import MaritalStatusService from '@/services-http/contract/MaritalStatusService';
import GenderService from '@/services-http/contract/GenderService';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import KinshipService from '@/services-http/contract/KinshipService';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import EligibilitiesMixin from '@/shared/mixins/sdi/eligibilitiesMixin';
import PlansMixin from '@/shared/mixins/sdi/plansMixin';
import ViewCriticizedViolationDialog from '@/components/DynamicDialogInformation/ViewCriticizedViolationDialog.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import PrincipalInformations from '@/components/Beneficiary/Holder/PrincipalInformations.vue';
import PlanInformation from '@/components/Beneficiary/Contracted/PlanInformation.vue';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import ViewCriticismAndInternalNotesModal from '@/components/DashboardMovementRecords/Movements/Modals/ViewCriticismAndInternalNotes/ViewCriticismAndInternalNotesModal.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import AllowedDatePicker from '@/components/AllowedDatePicker/AllowedDatePicker.vue';
import CarrierService from '@/services-http/contract/CarrierService';
import ContractService from '@/services-http/contract/ContractService';
import moment from 'moment';
import AddressInformations from '@/components/Beneficiary/Holder/AddressInformations.vue';

export default {
  name: 'DependentUpdate',
  components: {
    ViewCriticizedViolationDialog,
    PrincipalInformations,
    PlanInformation,
    ViolationRulesAlert,
    ViewCriticismAndInternalNotesModal,
    SnackbarCustomize,
    AllowedDatePicker,
    AddressInformations
  },
  data: () => ({
    moment: moment,
    validityDateMenu: null,
    validityDatePicker: null,
    carrierService: null,
    allowedDates: [],
    overlay: false,
    loading: false,
    isRHProtegido: false,
    controlHeight: false,
    controlWeight: false,
    isBeneficiary: false,
    isFreeMovement: false,
    loadingFinancialGroups: false,
    loadingContracts: false,
    loadingSubContracts: false,
    loadingEligibilities: false,
    loadingPlans: false,
    waitingDocumentUpload: false,
    isEdit: false,
    isCriticizedCarrier: false,
    isCriticizedBroker: false,
    confirmReturnModal: false,
    isDisabledFamilyCode: false,
    isDisabledFamilyCodeSequential: false,
    hasAuthorityViewInternalNoteTab: false,
    hasAuthorityViewButtonSelectedCriticismPending: false,
    hasAuthorityViewButtonSelectedCriticismResolved: false,
    alertGraceModal: false,
    validateGraceModal: false,

    maritalStatus: [],
    genders: [],
    kinships: [],
    documentsList: [],
    documentsData: [],
    financialGroups: [],
    contracts: [],
    subContracts: [],
    eligibilities: [],
    eligibilitiesIds: [],
    plans: [],
    violations: [],
    invalidGraceContractNames: [],
    gracePeriods: [],
    graceItems: [
      { text: 'Sim', value: true },
      { text: 'Não', value: false },
    ],

    holder: {
      cpf: null,
      name: null,
      registrationPlate: null,
      cardNumber: null,
    },
    dependent: {
      dependentCpf: null,
      dependentName: null,
      birthDate: null,
      maritalStatusId: null,
      genderId: null,
      degreeKinshipId: null,
      cns: null,
      dnv: null,
      motherName: null,
      height: null,
      weight: null,
      rne: null,
      planId: null,
      documents: [],
      eventDate: null,
      validityDate: null,
      stateBirth: null,
      familyCode: null,
      familyCodeSequential: null,
      registrationComplement: null,
      address: null,
      email: null,
      cellphone: null,
      telephone: null,
      identityDocumentNature: null, 
      identityDocumentNumber: null,
      identityDocumentEmissionDate: null,
      identityDocumentIssuer: null,
      identityDocumentState: null,
      identityDocumentCountry: null,
    },
    contractedPlanData: {
      financialGroupId: null,
      contractId: null,
      subContractId: null,
      eligibilityId: null,
      planId: null,
    },
    uploadInformationDocuments: {
      movementType: '',
      insuranceCarrierId: '',
      beneficiaryType: '',
    },
    movementRecord: {},
    payload: {},

    query: '',
    holderName: null,
    contractPlanId: null,
    formatDependentCpf: null,
    formatDependentBirthDate: null,
    formatDependentEventDate: null,
    formatDependentValidityDate: null,
    formatHolderCpf: null,
  }),

  async mounted() {
    this.overlay = true;
    this.verifyRouteRHProtegidoMixin();
    this.verifyFreeMovement();
    this.verifyTypeOperationForMovement();
    this.dataLoads();
    await this.loadSessionStorage();
    this.loadContractedPlans();
    await this.loadDocumentType();
    await this.loadDocumentsByMovementRecordId();
  },

  mixins: [
    ConstantsMixin,
    FinancialGroupsMixin,
    ContractsMixin,
    EligibilitiesMixin,
    PlansMixin,
    VerifyRoutesMixin,
  ],

  watch: {
    movementRecord: {
      deep: true,
      handler(val) {
        if (val) {
          this.holderName = val.holderName;
        }
      },
    },
  },

  methods: {
    async getAllowedDates() {
      await this.carrierService.GetAllowedDates([parseInt(this.contractedPlanData.contractId)], this.uploadInformationDocuments.movementType).then(response => {
        if (response.status === 200) {
          this.allowedDates = response.data;
        }
      })
    },
    updateAddressData(data) {
      if(!data) return;
      this.dependent.address = {};
      this.dependent.address.code = data.code;
      this.dependent.address.log = data.log;
      this.dependent.address.city = data.city;
      this.dependent.address.region = data.region;
      this.dependent.address.logType = data.logType;
      this.dependent.address.country = data.country;
      this.dependent.address.neighborhood = data.neighborhood;
      this.dependent.address.number = data.number;
      this.dependent.address.complement = data.complement;
      this.dependent.address.ibgeCode = data.ibgeCode;
    },
    updateValidityDate(value, dependent) {
      if (Boolean(dependent)) {
        dependent.validityDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.validityDateMenu = false;
      }
    },
    verifyTypeOperationForMovement() {
      if (sessionStorage.getItem('isCriticizedBroker')) {
        this.isCriticizedBroker = true;
      }
      if (sessionStorage.getItem('isCriticizedCarrier')) {
        this.isCriticizedCarrier = true;
      }
      if (sessionStorage.getItem('isEdit')) {
        this.isEdit = true;
      }
    },
    verifyFreeMovement() {
      if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
        this.isFreeMovement = true;
      }
    },
    dataLoads() {
      this.loadGenders();
      this.loadMaritalStatus();
      this.loadKiship();
    },
    async loadContractedPlans() {
      this.getFinancialGroups();
      this.getContractById(this.contractedPlanData.contractId);
      this.getSubContractsByContractId(this.contractedPlanData.subContractId);
      this.getEligibilitiesByContractAndSubcontracts(this.contractedPlanData.contractId, this.contractedPlanData.subContractId);
      this.getPlanByIdMixin(this.contractedPlanData.planId);
    },
    scrollTo() {
      this.$refs.tops.scrollIntoView({ behavior: 'smooth' });
    },
    async onClickFinish() {
      this.gracePeriods = [];
      this.isLoadingSave = true;
      this.formatPayload();
      setTimeout(async () => {
        if(this.isRHProtegido) {
          this.verifyEventDate();
        } else {
          this.verifyEventualUpdate();
        }
      }, 500);
    },
    async formatPayload() {
      this.formatDependentBirthDate = this.dependent.birthDate ? this.formatter.formatDateBRtoString(this.dependent.birthDate) : null;
      this.formatDependentEventDate = this.dependent.eventDate ? this.formatter.formatDateBRtoString(this.dependent.eventDate) : null;
      this.formatDependentValidityDate = this.dependent.validityDate ? this.formatter.formatDateBRtoString(this.dependent.validityDate) : null;
      this.formatDependentCpf = this.dependent.dependentCpf ? this.formatter.removeNonDigit(this.dependent.dependentCpf) : null;
      this.formatHolderCpf = this.holder.document ? this.formatter.removeNonDigit(this.holder.document) : null;
      this.dependent.height = this.dependent.height ? Number(this.dependent.height) : '';
      this.dependent.weight = this.dependent.weight ? Number(this.dependent.weight) : '';

      this.payload = {
        dependentName: this.dependent.dependentName,
        dependentCpf: this.formatDependentCpf,
        birthDate: this.formatDependentBirthDate,
        maritalStatusId: this.dependent.maritalStatusId,
        genderId: this.dependent.genderId,
        degreeKinshipId: this.dependent.degreeKinshipId,
        cns: this.dependent.cns,
        dnv: this.dependent.dnv ? this.formatter.unmaskDnv(this.dependent.dnv) : null,
        motherName: this.dependent.motherName,
        height: this.dependent.height,
        weight: this.dependent.weight,
        financialGroupId: this.contractedPlanData.financialGroupId,
        contractId: this.contractedPlanData.contractId,
        subContractId: this.contractedPlanData.subContractId,
        eligibilityId: this.contractedPlanData.eligibilityId,
        planId: this.contractedPlanData.planId,
        validityDate: this.formatDependentValidityDate,
        eventDate: this.conditionShowEventDate() ? this.formatDependentEventDate : null,
        stateBirth: this.dependent.stateBirth,
        holderName: this.holder.name,
        holderCpf: this.formatHolderCpf,
        holderRegistrationPlate: this.holder.registrationPlate,
        holderCardNumber: this.holder.cardNumber,
        documents: this.documentFormatted(),
        familyCode: this.dependent.familyCode ? this.dependent.familyCode : null,
        familyCodeSequential: this.dependent.familyCodeSequential ? this.dependent.familyCodeSequential : null,
        registrationComplement: this.dependent.registrationComplement ? this.dependent.registrationComplement : null,
        hasGracePeriod: this.dependent.hasGracePeriod,
        address: this.dependent.address,
        email: this.dependent.email,
        cellphone: this.dependent.cellphone ? this.dependent.cellphone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        telephone: this.dependent.telephone ? this.dependent.telephone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null,
        identityDocumentNature: this.dependent.identityDocumentNature, 
        identityDocumentNumber: this.dependent.identityDocumentNumber,
        identityDocumentEmissionDate: this.dependent.identityDocumentEmissionDate ? this.formatter.formatDateBRtoString(this.dependent.identityDocumentEmissionDate) : null,
        identityDocumentIssuer: this.dependent.identityDocumentIssuer,
        identityDocumentState: this.dependent.identityDocumentState,
        identityDocumentCountry: this.dependent.identityDocumentCountry,
      };

      this.payload = this.formatter.validateEmptyValues(this.payload);
    },
    getAddressObject(dependent) {
      const address = {};
      address.code = dependent.addressZip;
      address.log = dependent.address;
      address.city = dependent.addressCity;
      address.region = dependent.addressState;
      address.logType = dependent.addressType;
      address.country = dependent.addressCountry;
      address.neighborhood = dependent.addressNeighborhood;
      address.number = dependent.addressNumber;
      address.complement = dependent.addressComplement;
      address.ibgeCode = dependent.ibgeCode;
      return address;
    },
    verifyEventualUpdate() {
      if (this.isCriticizedCarrier) {
        this.payload = {};
        this.formatPayloadCriticizedCarrier();
        this.verifyMovement();
      } else {
        this.updateEventualDependents();
      }
    },
    formatPayloadCriticizedCarrier() {
      const listAux = [];
      listAux.push({
        document: this.formatDependentCpf,
        fullName: this.dependent.dependentName,
        birthDate: this.formatDependentBirthDate,
        maritalStatus: this.dependent.maritalStatusId,
        gender: this.dependent.genderId,
        relationship: this.dependent.degreeKinshipId,
        cns: this.dependent.cns,
        dnv: this.dependent.dnv ? this.formatter.unmaskDnv(this.dependent.dnv) : null,
        motherName: this.dependent.motherName,
        height: this.dependent.height,
        weight: this.dependent.weight,
        eventDate: this.conditionShowEventDate() ? this.formatDependentEventDate : null,
        rne: null,
        plans: [
          {
            financialGroupId: this.contractedPlanData.financialGroupId,
            contract: this.contractedPlanData.contractId,
            subcontract: this.contractedPlanData.subContractId,
            eligibility: this.contractedPlanData.eligibilityId,
            plan: this.contractedPlanData.planId,
            validityDate: this.formatDependentValidityDate,
            documents: this.documentFormatted(),
          },
        ],
        validityDate: this.formatDependentValidityDate,
        stateBirth: this.dependent.stateBirth,
        movementRecordParentId: this.movementRecord.id,
        insuranceCarrierId: this.movementRecord.insuranceCarrierId,
        dependentContractedPlanId: String(this.movementRecord.dependentContractedPlanId),
        beneficiaryContractedPlanId: String(this.movementRecord.beneficiaryContractedPlanId),
        holder: {
          cpf: this.formatHolderCpf,
          name: this.holder.name,
          registrationPlate: this.holder.registrationPlate,
          cardNumber: this.holder.cardNumber,
        },
        familyCode: this.dependent.familyCode ? this.dependent.familyCode : null,
        familyCodeSequential: this.dependent.familyCodeSequential ? this.dependent.familyCodeSequential : null,
        registrationComplement: this.dependent.registrationComplement ? this.dependent.registrationComplement : null,
      });
      this.payload = listAux;
    },
    insertEventualDependents() {
      this.violations = [];
      this.overlay = true;
      this.$refs.ViewCriticismAndInternalNotesModal.close();

      let criticsm = [];

      if (sessionStorage.getItem('criticismRules')) {
        criticsm = JSON.parse(sessionStorage.getItem('criticismRules')).filter((movement) => movement.classification && movement.classification === 'INTERNAL').map((movement) => movement.id);
      }

      this.payload[0].internalCriticisms = criticsm;

      this.movementRecordService.InsertEventualDependents(this.payload, this.isFreeMovement).then(() => {
        this.openConfirmReturnModal();
      }).catch((error) => {
        this.handleError(error);
      });
    },
    updateEventualDependents() {
      this.violations = [];
      this.overlay = true;

      this.movementRecordService.UpdateEventualDependents(this.movementRecord.id, this.payload, this.isFreeMovement, this.isCriticizedBroker).then(() => {
        this.openConfirmReturnModal();
      }).catch((error) => {
        this.handleError(error);
      });
    },
    handleError(error) {
      this.overlay = false;
      this.isLoadingSave = false;
      this.documentsData = [];

      if (error.response && error.response.status === 422) {
        this.violations = [...error.response.data.violations];
      }
    },
    verifyMovement() {
      const queryParams = {
        movementRecord: this.movementRecord,
        isRHProtegido: this.isRHProtegido,
        viewType: 'confirmCorrectionMovement',
      };
      this.$refs.ViewCriticismAndInternalNotesModal.open(queryParams);
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.genders = response.data;
        }
      });
    },
    async loadMaritalStatus() {
      await this.maritalStatusService.FindAll().then((response) => {
        if (response && response.data) {
          this.maritalStatus = response.data;
        }
      });
    },
    async loadKiship() {
      await this.kinshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.kinships = response.data.filter(kinship =>
                              kinship.id !== 22
                              || kinship.code !== 'Titular'
                              || kinship.description !== 'Titular');
          this.kinships.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    onClickBack() {
      this.redirectRouter('DependentInclusion', { isRHProtegido: this.isRHProtegido });
    },
    onClickCancel() {
      const { isRHProtegido } = this;
      let name;

      if (this.isEdit || this.isCriticizedBroker || this.isCriticizedCarrier) {
        name = 'Movement';
      } else {
        name = isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      }

      this.redirectRouter(name, { isRHProtegido });
    },
    async loadDocumentType() {
      this.uploadInformationDocuments.movementType = 'INSERT_DEPENDENT';
      this.uploadInformationDocuments.beneficiaryType = 'DEPENDENT';
      this.uploadInformationDocuments.insuranceCarrierId = this.movementRecord.insuranceCarrierId;
      const data = `movementType=${this.uploadInformationDocuments.movementType}&insuranceCarrierId=${this.uploadInformationDocuments.insuranceCarrierId}&beneficiaryType=${this.uploadInformationDocuments.beneficiaryType}`;
      await this.findDocumentType(data);
    },
    async findDocumentType(data) {
      await this.documentTypeService.FindAllDocumentType(data).then(async (response) => {
        this.documentsList = await response.data.content.map((element) => ({
          id: element.id,
          name: element.name,
          description: element.description,
          file: null,
        }));
      }).catch(() => {
        this.overlay = false;
      }).finally(() => {
        this.overlay = false;
      });
    },
    async uploadDocument(item) {
      if (item) {
        if ((item.file && !this.rule.isValidFileSize(item.file.size)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png'))) {
          return;
        }
      }
      if (item.file !== null && typeof item.file !== 'undefined') {
        await this.clearDocument(item);
        const documentTypeId = item.documentTypeId ? item.documentTypeId : item.id;
        this.waitingDocumentUpload = true;
        this.disableButton = true;
        this.documentService.UploadDocument(documentTypeId, item.file).then((response) => {
          this.documentsData.push({
            id: response.data,
            name: item.name,
            file: item.file,
            type: item.file.type,
          });
          // eslint-disable-next-line no-param-reassign
          item.idRecentlyUploaded = response.data;
          this.waitingDocumentUpload = false;
        }).catch(() => {
          this.waitingDocumentUpload = false;
        });
      } else {
        await this.clearDocument(item);
      }
    },
    async clearDocument(item) {
      if (item) {
        if (this.documentsData && this.documentsData.length > 0) {
          const documentIndex = this.documentsData.findIndex((document) => document.documentTypeId === item.documentTypeId);
          if (documentIndex >= 0) {
            this.documentsData.splice(documentIndex, 1);
          }
        }
      }
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        document.getElementById(id).focus();
        switch (id) {
          case 'fieldCheckedWeight':
            this.controlWeight = true;
            this.controlHeight = false;
            break;
          case 'fieldCheckedHeight':
            this.controlHeight = true;
            this.controlWeight = false;
            break;
          default:
            this.controlHeight = false;
            this.controlWeight = false;
            break;
        }
      } else {
        this.controlHeight = false;
        this.controlWeight = false;
      }
    },
    async loadSessionStorage() {
      if (sessionStorage.getItem('movementRecord')) {
        this.movementRecord = await JSON.parse(sessionStorage.getItem('movementRecord'));
        this.loadHolderInfoByMovementRecord();
        await this.loadContractedPlan();
        await this.mapBeneficiaryData();
      }
    },
    async loadContractedPlan() {
      this.contractedPlanData.financialGroupId = this.movementRecord.financialGroupId ? Number(this.movementRecord.financialGroupId) : null;
      this.contractedPlanData.contractId = this.movementRecord.contractId ? Number(this.movementRecord.contractId) : null;
      this.contractedPlanData.subContractId = this.movementRecord.subContractId ? Number(this.movementRecord.subContractId) : null;
      this.contractedPlanData.eligibilityId = this.movementRecord.contractEligibilityId ? Number(this.movementRecord.contractEligibilityId) : null;
      this.contractedPlanData.planId = this.movementRecord.plan ? Number(this.movementRecord.plan) : null;
      this.contractedPlanData.validityDate = this.movementRecord.startDate ? this.movementRecord.startDate : null;
    },
    async loadHolderInfoByMovementRecord() {
      this.holder.document = this.movementRecord.holderDocumentNumber;
      this.holder.name = this.movementRecord.holderName ? this.formatter.formatToTitleCase(this.movementRecord.holderName) : null;
      this.holder.registrationPlate = this.movementRecord.registrationPlate;
      this.holder.cardNumber = this.movementRecord.beneficiaryCard;
    },
    async mapBeneficiaryData() {
      this.dependent = {
        dependentCpf: this.movementRecord.insuredDocumentNumber,
        dependentName: this.movementRecord.insuredName ? this.formatter.formatToTitleCase(this.movementRecord.insuredName) : null,
        birthDate: this.movementRecord.birthDate ? this.formatter.formatDate(this.movementRecord.birthDate) : null,
        maritalStatusId: this.movementRecord.maritalStatus ? Number(this.movementRecord.maritalStatus) : null,
        genderId: this.movementRecord.gender ? Number(this.movementRecord.gender) : null,
        degreeKinshipId: this.movementRecord.degreeKinship ? Number(this.movementRecord.degreeKinship) : null,
        cns: this.movementRecord.cns,
        dnv: this.movementRecord.dnv ? this.formatter.unmaskDnv(this.movementRecord.dnv) : null,
        motherName: this.movementRecord.motherName ? this.formatter.formatToTitleCase(this.movementRecord.motherName) : null,
        height: this.movementRecord.height,
        weight: this.movementRecord.weight,
        rne: this.movementRecord.rne,
        documents: this.documentFormatted(),
        eventDate: this.movementRecord.eventDate ? this.formatter.formatDate(this.movementRecord.eventDate) : null,
        validityDate: this.movementRecord.startDate ? this.formatter.formatDate(this.movementRecord.startDate) : null,
        stateBirth: this.movementRecord.stateBirth ? this.formatter.formatDate(this.movementRecord.stateBirth) : null,
        familyCode: this.movementRecord.familyCode ? this.movementRecord.familyCode : null,
        familyCodeSequential: this.movementRecord.familyCodeSequential ? this.movementRecord.familyCodeSequential : null,
        registrationComplement: this.movementRecord.registrationComplement ? this.movementRecord.registrationComplement : null,
        hasGracePeriod: this.movementRecord.hasGracePeriod,
        address: this.getAddressObject(this.movementRecord),
        identityDocumentCountry: this.movementRecord.documentCountryIssue ? this.movementRecord.documentCountryIssue : null,
        identityDocumentState: this.movementRecord.documentStateIssuer ? this.movementRecord.documentStateIssuer : null,
        identityDocumentIssuer: this.movementRecord.rgDocumentIssuingBody ? this.movementRecord.rgDocumentIssuingBody : null,
        identityDocumentEmissionDate: this.movementRecord.rgDocumentIssueDate ? this.formatter.formatDate(this.movementRecord.rgDocumentIssueDate) : null,
        telephone: this.movementRecord.homephoneDDD && this.movementRecord.homephoneNumber ? this.movementRecord.homephoneDDD + this.movementRecord.homephoneNumber : null,
        cellphone: this.movementRecord.cellphoneDDD && this.movementRecord.cellphoneNumber ? this.movementRecord.cellphoneDDD + this.movementRecord.cellphoneNumber : null,
        identityDocumentNature: this.movementRecord.natureIdentifyDocument ? this.movementRecord.natureIdentifyDocument : null,
        email: this.movementRecord.email ? this.movementRecord.email : null,
        identityDocumentNumber: this.movementRecord.rgDocumentIdentify,
      };
    },
    async loadDocumentsByMovementRecordId() {
      if (this.movementRecord && this.movementRecord.id) {
        const queryString = `?movementRecordId=${this.movementRecord.id}`;
        await this.documentService.FindDocumentByFilters(queryString).then(async (response) => {
          if (response && response.data && response.data.length > 0) {
            if (this.documentsList && this.documentsList.length > 0) {
              this.documentsList.forEach(async (documentList) => {
                await response.data.forEach((document) => {
                  if (documentList.name === document.documentType.name) {
                    // eslint-disable-next-line no-param-reassign
                    documentList.file = new File([document.name], document.name, { type: 'image/png' });
                    // eslint-disable-next-line no-param-reassign
                    documentList.documentTypeId = document.documentType.id;
                    // eslint-disable-next-line no-param-reassign
                    documentList.id = document.id;
                    // eslint-disable-next-line no-param-reassign
                    documentList.loadingDownload = false;
                  }
                });
              });
              this.loadDocumentsInDependent();
            }
          }
        }).catch(() => {
          this.overlay = false;
        }).finally(() => {
          this.overlay = false;
        });
      }
    },
    loadDocumentsInDependent() {
      this.documentsList.forEach((document) => {
        if (document && document.documentTypeId) {
          this.dependent.documents.push(document);
        }
      });
    },
    documentFormatted() {
      const documentFormatted = this.documentsList.filter((element) => element.file !== null && typeof element.file !== 'undefined').map((element) => ({ id: element.idRecentlyUploaded ? element.idRecentlyUploaded : element.id }));
      return documentFormatted;
    },
    conditionShowEventDate() {
      if (this.dependent.degreeKinshipId && this.kinships && this.kinships.length > 0) {
        const degreeKinshipIdSelected = this.kinships.find((kinship) => kinship.id === this.dependent.degreeKinshipId);
        switch (degreeKinshipIdSelected.code) {
          case 'tutelado':
          case 'marido':
          case 'esposa':
          case 'conjuge':
          case 'cônjuge':
          case 'companheiro':
          case 'filhoadotivo':
          case 'enteado':
            return true;

          default: return false;
        }
      }
      return false;
    },
    getPlanName(planId) {
      let text = 'Nenhum Plano Selecionado';
      if (typeof planId === 'number' && this.plans && this.plans.length > 0) {
        const planFound = this.plans.filter((plan) => plan.id === planId);
        if (planFound && planFound.length > 0) {
          text = `${planFound[0].name}`;
        }
      }
      return text;
    },
    openConfirmReturnModal() {
      this.confirmReturnModal = true;
    },
    closeConfirmReturnModal() {
      this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
      this.confirmReturnModal = false;
      this.isLoading = false;
    },
    observerHolderInformationData(data) {
      this.holder = data;
    },
    observerContractedPlan(data) {
      this.contractedPlanData = data;
    },
    async downloadDocuments(item) {
      if (item && item.file !== null) {
        // eslint-disable-next-line no-param-reassign
        item.loadingDownload = true;
        this.$forceUpdate();
        const id = item.idRecentlyUploaded ? item.idRecentlyUploaded : item.id;
        const queryString = `?idDocuments=${id}`;

        await this.documentService.DownloadFileDocuments(queryString).then((response) => {
          if (response) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const name = this.validateNameInResponseHeaders(contentDispositionHeader);
            const nameDecoded = decodeURIComponent(name);

            fileLink.href = fileURL;
            fileLink.setAttribute('download', nameDecoded);
            document.body.appendChild(fileLink);
            fileLink.click();

            this.selectedItems = [];
            // eslint-disable-next-line no-param-reassign
            item.loadingDownload = false;
            this.$forceUpdate();
          }
        }).catch(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          item.loadingDownload = false;
          this.$forceUpdate();
        });
      }
    },
    validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    },
    setSnackbarCustomize(type, message) {
      this.$refs.SnackbarCustomize.open(type, message);
    },
    async verifyEventDate() {
      let validEventDate = false;
      let eventDate = null;
      this.invalidGraceContractNames = [];

      if(this.payload.eventDate !== null) {
        eventDate = this.payload.eventDate;
        validEventDate = true;
      } else if (this.conditionGraceEventDate() &&
                  this.payload.birthDate !== null &&
                  this.payload.birthDate.trim().length > 0) {
        eventDate = this.payload.birthDate;
        validEventDate = true;
      } else {
        validEventDate = false;
      }

      let params = [];
      if(eventDate !== null) {
        params.push({
          parentContractId: this.payload.contractId,
          eventDate: eventDate,
          startDate: this.payload.validityDate,
        })
      }
      this.invalidGraceContractNames.push(this.getXippCommercialName(this.payload.contractId));


      if(!validEventDate) {
        this.alertGraceModal = true;
      } else {
        await this.getValidateGracePeriod(params);
      }

      return validEventDate;
    },
    conditionGraceEventDate() {
      if (this.dependent.degreeKinshipId && this.kinships && this.kinships.length > 0) {
        const relationshipSelected = this.kinships.find((kinship) => kinship.id === this.dependent.degreeKinshipId);
        switch (relationshipSelected.code) {
          case 'filho':
          case 'filhouniversitario':
          case 'filhoinvalido':
            return true;

          default: return false;
        }
      }
      return false;
    },
    getXippCommercialName(contractId) {
      let contract = this.contracts.find(function(carrier) {
        return (carrier.id === contractId)
      })

      return contract.carrier_id.xipp_commercial_name;
    },
    getValidateGracePeriod(params) {
      this.contractService.ValidateGracePeriod(params).then((response) => {
        if(response) {
          let hasGracePeriod = false;
          response.data.forEach(element => {
            if(element.hasGracePeriod === true) {
              hasGracePeriod = true;
              this.gracePeriods = response.data;
            }
          });
          if(hasGracePeriod) {
            this.validateGraceModal = true;
          } else {
            this.payload.hasGracePeriod = null;
            this.verifyEventualUpdate();
          }
        }
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao validar carência');
      });
    },
    closeAlertGraceModal() {
      this.alertGraceModal = false;
    },
    closeValidateGraceModal() {
      this.validateGraceModal = false;
    },
    confirmGracePeriod() {
      this.payload.hasGracePeriod = true;
      this.closeValidateGraceModal();
      this.verifyEventualUpdate();
    },
    nonConfirmGracePeriod() {
      this.payload.hasGracePeriod = false;
      this.closeValidateGraceModal();
      this.verifyEventualUpdate();
    },
  },
  async created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.genderService = new GenderService();
    this.maritalStatusService = new MaritalStatusService();
    this.beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
    this.kinshipService = new KinshipService();
    this.movementRecordService = new MovementRecordService();
    this.documentService = new DocumentService();
    this.documentTypeService = new DocumentTypeService();
    this.carrierService = new CarrierService();
    this.contractService = new ContractService();
  },
};
</script>
<style scoped>
.btn-dialog {
  width: 200px;
  font-size: 16px;
}
</style>

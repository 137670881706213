import ContractService from '@/services-http/contract/ContractService';

export default {
  methods: {
    async getContractsByFinancialGroupIdVerifyRhProtegido(financialGroupId, isRhProtegido) {
      if (financialGroupId) {
        const contractService = new ContractService();
        this.loadingContracts = true;
        this.contracts = [];
        this.subContracts = [];
        let query = `financialGroupIds=${financialGroupId}`;

        if (isRhProtegido) {
          query += `&allowMovementByProtectedHR=${isRhProtegido}`;
        }

        await contractService.FindAllByFilters(query).then((response) => {
          if (response && response.data && response.data.length > 0) {
            const contracts = response.data.filter((c) => c.subcontract === false);
            this.formatContractDescription('byFinancialGroupId', contracts);
          }
          this.loadingContracts = false;
        }).catch(() => {
          this.loadingContracts = false;
        });
      }
    },
    async loadAllContracts() {
      const contractService = new ContractService();

      await contractService.FindAll()
        .then((response) => response.data)
        .then((result) => this.contracts = result);
    },
    async getContractsByFinancialGroupId(financialGroupId) {
      if (financialGroupId) {
        const contractService = new ContractService();
        this.loadingContracts = true;
        this.contracts = [];
        this.subContracts = [];
        const query = `financialGroupIds=${financialGroupId}`;

        await contractService.FindAllByFilters(query).then((response) => {
          if (response && response.data) {
            const contracts = response.data.filter((c) => c.subcontract === false);
            this.formatContractDescription('byFinancialGroupId', contracts);
          }
          this.loadingContracts = false;
        }).catch(() => {
          this.loadingContracts = false;
        });
      }
    },
    async getSubContractsByContractId(contractId) {
      if (contractId) {
        const contractService = new ContractService();
        this.loadingSubContracts = true;
        this.subContracts = [];
        this.eligibilities = [];
        await contractService.FindByParentId(contractId).then((response) => {
          if (response && response.data) {
            const subContracts = response.data.filter((c) => c.subcontract === true);
            this.formatSubContractDescription(subContracts);
          }
          this.loadingSubContracts = false;
        }).catch(() => {
          this.loadingSubContracts = false;
        });
      }
    },
    async getContractById(id) {
      if (id) {
        const contractService = new ContractService();
        this.loadingContracts = true;
        this.contracts = [];
        contractService.FindById(id).then((response) => {
          if (response && response.data) {
            const contractsAux = [];
            contractsAux.push(response.data);
            const contracts = contractsAux.filter((c) => c.subcontract === false);
            this.formatContractDescription('byContractId', contracts);
          }
          this.loadingContracts = false;
        }).catch(() => {
          this.loadingContracts = false;
        });
      }
    },
    async getContractNameById(contractSelected) {
      const contractFound = this.contracts.find((contract) => contract.id === contractSelected);
      return contractFound ? (`${(contractFound.benefit_id && contractFound.benefit_id.name ? contractFound.benefit_id.name : contractFound.benefit_id ? contractFound.benefit_id : '-')} - ${(contractFound.carrier_id && contractFound.carrier_id.xipp_commercial_name ? contractFound.carrier_id.xipp_commercial_name : '-')} - ${contractFound.policy ? contractFound.policy : '-'} - ${contractFound.contract_owner_id && contractFound.contract_owner_id.legal_name ? contractFound.contract_owner_id.legal_name : '-'}`) : null;
    },
    async getSubContractNameById(subContractSelected) {
      const subContractFound = this.subContracts.find((subContract) => subContract.id === subContractSelected);
      return subContractFound ? (`${subContractFound.subcontract_number ? subContractFound.subcontract_number : '-'} - ${subContractFound.contract_owner_id && subContractFound.contract_owner_id.legal_name ? subContractFound.contract_owner_id.legal_name : '-'}`) : null;
    },
    formatContractDescription(type, contracts) {
      if (contracts) {
        this.contracts = contracts.map((item) => {
          const { benefit_id, carrier_id, policy, contract_owner_id } = item;
          const name = benefit_id && benefit_id.name ? benefit_id.name : benefit_id || '-';
          const commercialName = carrier_id && carrier_id.xipp_commercial_name ? carrier_id.xipp_commercial_name : '-';
          const legalName = contract_owner_id && contract_owner_id.legal_name ? contract_owner_id.legal_name : '-';
          const text = type === 'byContractId' ? `${name} - ${commercialName} - ${policy || '-'} - ${legalName}` : `${benefit_id || '-'} - ${commercialName} - ${policy || '-'} - ${legalName}`;

          return {
            ...item,
            text,
          };
        });
      }
    },
    formatSubContractDescription(subContracts) {
      if (subContracts) {
        this.subContracts = subContracts.map((item) => {
          const subcontractNumber = item.subcontract_number ? item.subcontract_number : '-';
          const contractOwnerLegalName = item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-';
          const text = `${subcontractNumber} - ${contractOwnerLegalName}`;

          return {
            ...item,
            text,
          };
        });
        this.subContracts.sort((a, b) => a.subcontract_number.localeCompare(b.subcontract_number, { numeric: true }));
      }
    },
  },
};

<template>
  <v-footer
    class="pa-0">
    <v-row class="d-flex justify-end">
      <v-col
        cols="12"
        md="3"
        lg="2">
        <v-btn
          x-large
          block
          outlined
          :color="defaultColor"
          @click="backToHomeAllMenus()"
          style="height: 57px">
          Voltar
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';

export default {
  name: "DefaultFooter",

  data: () => ({
    isRHProtegido: false,
  }),

  props: {
    defaultColor: {
      type: String,
      required: false,
      default: "textPrimary",
    },
  },

  mixins: [
    VerifyRoutesMixin,
  ],

  methods: {
    backToHomeAllMenus() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    }
  },

  created() {
    this.isRHProtegido = this.$route.query.isRHProtegido;
  },
}
</script>
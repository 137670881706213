<template>
  <v-container
    fluid>
    <h2
      class="grey--text my-4 ml-3">
      Documentos de Movimentação
    </h2>

    <v-container
      fluid
      class="mb-6 py-0 px-2">
      <DefaultFilter
        :refFilter="'MovementDocumentFilter'"
        :model="movementDocumentFilter"
        :filters="filters"
        :showRangeFilter="false"
        :showExpansion="filters.length > 3"
        :loading="loading"
        @search="search"
      />
    </v-container>

    <v-row
      class="d-flex justify-end mx-1">
      <v-col
        :class="{
          'col-3': $vuetify.breakpoint.mdAndUp,
          'col-12': $vuetify.breakpoint.smAndDown,
        }">
        <v-btn
          outlined
          x-large
          block
          @click="createMovementDocument"
          color="primary">
          Nova Configuração
        </v-btn>
      </v-col>
    </v-row>

    <DefaultTable
      ref="MovementDocumentTable"
      :items="movementDocuments"
      :tableConfig="tableConfig"
      :loading="loading"
      :totalPages="totalPages"
      @itemsPerPage="itemsPerPage = $event"
      @page="page = $event"
      @sort="sort = $event"
      @search="search">
      <template v-slot:no-data>
        <span>
          Nenhum dado encontrado.
        </span>
      </template>

      <template v-slot:[`item.carrier`]="{ item }">
        {{ item.carrier ? item.carrier.name : '-' }}
      </template>

      <template v-slot:[`item.contract`]="{ item }">
        {{ item.contract ? item.contract.name : '-' }}
      </template>

      <template v-slot:[`item.documentType`]="{ item }">
        {{ item.documentType ? item.documentType.name : '-' }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu transition="slide-y-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="textPrimary"
              dark
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>

          <TodoListActions
            :actions="getTodoActions(item)"
          />
        </v-menu>
      </template>
    </DefaultTable>


    <v-container
      fluid>
      <DefaultFooter />
    </v-container>

    <SnackbarCustomize
      ref="SnackbarCustomize"
    />

    <ConfirmationModal
			ref="ConfirmationModal"
			@confirm="deleteMovementDocument"
			:title="'Excluir Documento de Movimentção'"
			:description="'Tem certeza que deseja excluir este registro?'"
      :color="'primary'"
		/>
  </v-container>
</template>

<script>
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import ConfirmationModal from '@/components/Modals/ConfirmationModal/ConfirmationModal.vue';
import MovementDocumentService from '@/services-http/sdi/MovementDocumentService';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import DefaultFilter from '@/components/Default/DefaultFilter/DefaultFilter.vue';
import DefaultFooter from '@/components/Default/DefaultFooter/DefaultFooter.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import DefaultTable from '@/components/Default/DefaultTable/DefaultTable.vue';
import DefaultForm from '@/components/Default/DefaultForm/DefaultForm.vue';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import DocumentMixin from '@/shared/mixins/document/documentMixin';
import ContractMixin from '@/shared/mixins/sdi/contractsMixin';

export default {
  name: "MovementDocument",

  components: {
    DefaultFooter,
    DefaultTable,
    DefaultForm,
    TodoListActions,
    SnackbarCustomize,
    DefaultFilter,
    ConfirmationModal,
  },

  data: () => ({
    loading: false,
    movementDocumentFilter: {},
    totalPages: 1,
    itemsPerPage: 25,
    page: 1,
    resetAdvancedSearch: false,
    movementDocumentService: null,
    movementDocuments: [],
    contracts: [],
    insuranceCarriers: [],
    sort: "id,desc",
    showForm: false,
    rule: null,
    itemToDelete: null,
  }),

  mixins: [
    AuthorityManagementMixin,
    InsuranceCarriersMixin,
    VerifyRoutesMixin,
    DocumentMixin,
    ContractMixin,
  ],

  methods: {
    cleanFilter() {
      this.movementDocumentFilter = {}
    },

    createMovementDocument() {
      this.redirectRouter('MovementDocumentForm', { new: true });
    },

    editMovementDocument(item) {
      this.redirectRouter('MovementDocumentForm', {}, item);
    },

    getTodoActions(item) {
      const actions = [
        {
          label: 'Editar',
          icon: 'fas fa-pencil-alt',
          color: 'textPrimary',
          condition: true,
          disabled: false,
          handler: () => this.editMovementDocument(item),
        },
        {
          label: 'Excluir',
          icon: 'fa fa-trash-alt',
          color: 'textPrimary',
          condition: this.hasPermission('sdi_documento_movimentacao_excluir'),
          disabled: false,
          handler: () => this.startDeleteMovementDocument(item),
        },
      ];

      return actions;
    },

    startDeleteMovementDocument(item) {
      this.$refs.ConfirmationModal.open();
      this.itemToDelete = item;
    },

    async deleteMovementDocument() {
      this.loading = true;

      await this.movementDocumentService.Delete(this.itemToDelete.id)
        .catch((err) => this.$refs.SnackbarCustomize.open('error', err))
        .finally(() => {
          this.loading = false;
          this.search();
        });
    },

    async search() {
      this.loading = true;

      const filter = this.mountFilter(this.movementDocumentFilter)

      const params = new URLSearchParams({
				page: this.page-1,
				size: this.itemsPerPage,
				sort: !!this.sort ? this.sort : "id,desc"
			})

      await this.movementDocumentService.Get(filter, params)
        .then((response) => {
          this.totalPages = response.data.totalElements;
          return response.data.content;
        })
        .then((result) => this.movementDocuments = result)
        .catch((err) => this.$refs.SnackbarCustomize.open('error', err))
        .finally(() => this.loading = false);
    },

    mountFilter(filter) {
      let endpoint = "";

      if (filter.carrier) endpoint += `?carrier.id=${filter.carrier.toString()}`;

      if (filter.contract) endpoint != ""
        ? endpoint += `&contract.id=${filter.contract.toString()}`
        : endpoint += `?contract.id=${filter.contract.toString()}`;

      if (filter.documentType) endpoint != ""
        ? endpoint += `&documentType.id=${filter.documentType}`
        : endpoint += `?documentType.id=${filter.documentType}`;

      return endpoint
    },
  },

  computed: {
    filters() {
      const inputProps = {
        dense: true,
        outlined: true,
        clearable: true,
        hideDetails: true,
        color: "primary--text",
        itemColor: "primary--text",
      };

      const comboProps = {
        itemText: "name",
        itemValue: "id",
      };

      const colsProps = {
        cols:"12",
        xl:"4",
        lg:"4",
        md:"4",
        sm:"6",
      };

      let fields = [
        {
          name: 'carrier',
          type: 'autocomplete',
          attrs: {
            items: this.insuranceCarriers,
            itemText: "xipp_commercial_name",
            itemValue: "id",
            label: 'Operadora',
            ...inputProps,
          },
          col: {
            attrs: colsProps,
          },
        },
        {
          name: 'contract',
          type: 'autocomplete',
          attrs: {
            items: this.contracts,
            label: 'Contrato',
            ...comboProps,
            ...inputProps,
          },
          col: {
            attrs: colsProps,
          },
        },
        {
          name: 'documentType',
          type: 'autocomplete',
          attrs: {
            items: this.documentTypes,
            label: 'Tipo de Documento',
            ...comboProps,
            ...inputProps,
          },
          col: {
            attrs: colsProps,
          },
        },
      ];

      return fields;
    },

    tableConfig() {
      const fieldProps = {
        align: 'center',
        class: 'text--truncate',
      };

      return  {
        itemKey: 'id',
        calculateWidths: true,
        headers: [
          {
            text: 'Operadora',
            value: 'carrier',
            slot: false,
            ...fieldProps,
          },
          {
            text: 'Contrato',
            value: 'contract',
            slot: false,
            ...fieldProps,
          },
          {
            text: 'Tipo do Documento',
            value: 'documentType',
            slot: false,
            ...fieldProps,
          },
          {
            text: 'Ações',
            value: 'actions',
            align: 'end',
            slot: false,
            sortable: false,
          },
        ]
      };
    },
  },

  created() {
    this.movementDocumentService = new MovementDocumentService();
  },

  mounted() {
    this.getInsuranceCarriers();
    this.loadDocumentTypes();
    this.loadAllContracts();
  },
}
</script>
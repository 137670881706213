import HttpService from '../HttpService'

export default class BeneficiaryTypeService {
  constructor() {
    this._httpService = new HttpService('/sdi/beneficiary-type');
  }

  async FindAll() {
    return await this._httpService.get('');
  }
}

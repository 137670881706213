import MovementRecordTypeService from "@/services-http/sdi/MovementRecordTypeService";
import BenefitService from "@/services-http/odoo/BenefitService";
import KinshipService from "@/services-http/contract/KinshipService";
import ContractBenefitService from "@/services-http/contract/ContractBenefitService"

export default {
  data: () => ({
    movementTypeService: null,
    benefitService: null,
    kinshipService: null,
    contractBenefitService: null,
    movementTypes: [],
    benefitTypes: [],
    filterFileMovements: {
      onlyFtpIntegration: false,
      protocolNumber: '',
      createdBy: '',
      financialGroup: '',
      receivingDateStart: '',
      receivingDateEnd: '',
      status: '',
      withErrors:''
    },
    filterShippingBatches: {
      id: '',
      insuranceCarrierId: [],
      createdAtStart: '',
      createdAtEnd: '',
      sendAtStart: '',
      sendAtEnd: '',
      protocolNumber: '',
      status: '',
      createdBy: '',
      batchType: '',
      movementInsuranceCarrierId: '',
      onlyManualProtocol: false,
      financialGroupIds: [],
    },
    filterMovements: {
      protocolNumber: '',
      movementRecordId: '',
      movementInsuranceCarrierId: '',
      fileDataId: '',
      financialGroup: '',
      contract: '',
      subContract: '',
      name: '',
      document: '',
      nameDependent: '',
      documentDependent: '',
      movementType: [],
      status: [],
      dateType: 'isMovementDate',
      receivingDateStart: '',
      receivingDateEnd: '',
      insuranceCarrierIds: [],
      onlyManualProtocol: false,
      onlyErrors: false,
      appOrigin: 'allChannels',
      pendingMovements: false,
      internalCriticism: 'both',
      withDependents: false,
    },
    filterMyReports: {
      financialGroupId: '',
      reportLayout: '',
      status: '',
      receivingDateStart: '',
      receivingDateEnd: '',
    },
    loadingBenefits: false,
  }),

  methods: {
    async loadMovementTypes() {
      await this.movementTypeService.FindAll().then((response) => {
        this.movementTypes = response.data.sort((a, b) => (a.description > b.description ? 1 : -1));
        this.movementTypes = this.movementTypes.map(
          (movementType) => {
            movementType.name = movementType.name.charAt(0).toUpperCase() + movementType.name.slice(1).toLowerCase();

            return movementType;
          })
      });
    },

    async loadBenefitTypes() {
      await this.benefitService.FindAll().then((response) => {
        if (response && response.data && response.data.records.length) {
          this.benefitTypes = response.data.records.filter((benefit) => benefit.xipp_contract_record_type_code !== null);
        }
      });
    },

    async loadBenefitsByCarrierId(carrierId) {
      this.loadingBenefits = true;

      await this.contractBenefitService.FindBenefitsByCarrierId(carrierId)
        .then((response) => response.data)
        .then((result) => {
          const benefitTypes = result.map((benefit) => (
            { id: benefit.id, name: benefit.name, benefitTypeCode: benefit.benefitTypeCode.toUpperCase()}
          ));
          this.benefitTypes = benefitTypes;
        })
        .finally(() => this.loadingBenefits = false);
    },

    async loadBenefitsByContractId(contractId) {
      this.loadingBenefits = true;

      await this.contractBenefitService.FindBenefitsByContractId(contractId)
        .then((response) => response.data)
        .then((result) => {
          const benefitTypes = result.map((benefit) => (
            { id: benefit.id, name: benefit.name, benefitTypeCode: benefit.benefitTypeCode.toUpperCase()}
          ));
          this.benefitTypes = benefitTypes;
        })
        .finally(() => this.loadingBenefits = false);
    },

    async loadKinships() {
      await this.kinshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.kinships = response.data;
          this.kinships.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },

    getMovementTypeDescription(movementType) {
      if (this.movementTypes.length === 0) return '';

      const movementTypeIndex = this.movementTypes.findIndex((el) => el.code === movementType);

      return this.movementTypes[movementTypeIndex].description;
    },
  },

  created() {
    this.movementTypeService = new MovementRecordTypeService();
    this.contractBenefitService = new ContractBenefitService();
    this.benefitService = new BenefitService();
    this.kinshipService = new KinshipService();
  },
};

<template>
  <v-autocomplete
    :class="{
      'advancedSearch rounded-pill fill-height': true,
      'hideResult': Array.isArray(result) && result.length > 0
    }"
    :items="items"
    placeholder="Busca de layout..."
    append-icon="mdi-magnify"
    return-object
    hide-details
    :dense="isDense"
    no-filter
    outlined
    @update:search-input="updateText">
    <template v-slot:item="{ item }">
      <div
        class="wineLabel--text fill-height d-flex align-center"
        style="width: 100%; height: 48px;"
        @click="() => setResult(item)"
        v-if="item">
        <span>
          Pesquisar por
          <span
            class="font-weight-bold"
            v-text="item.label"
          />
          para: <span v-text="text" />
        </span>
      </div>
    </template>

    <template
      v-slot:selection="{ item, index }">
      <div
        :class="{
          'd-flex flex-nowrap fill-height overflow-auto': true,
          'pl-4': Array.isArray(result) && result.length === 0
        }"
        v-if="Array.isArray(result) && result.length > 0">
        <v-row
          v-for="(search, searchIndex) in result"
          :key="search.uniqueKey"
          class="fill-height"
          no-gutters>
          <v-col
            class="fill-height d-flex flex-row align-center text-truncate elevation-3 mr-2">
            <div
              :key="`${search.key}-${search.uniqueKey}`"
              :class="`fill-height d-flex flex-row align-center ${color} white--text mr-2 px-4`">
              {{ getItemLabel(search.key) }}
            </div>

            <span
              :key="`${search.value}-${search.uniqueKey}-${valueIndex}`"
              class="fill-height d-flex flex-row align-center pr-2"
              v-for="(value, valueIndex) in search.value">
              {{
                !!search.value
                && search.value.length > 0
                && valueIndex < search.value.length-1
                  ? `${value} ou`
                  : value
              }}
            </span>

            <v-btn
              icon
              outlined
              small
              class="mr-2"
              @click="removeFilter(searchIndex)">
              <v-icon
                :color="color">
                close
              </v-icon>
            </v-btn>

            <v-divider
              vertical
            />
          </v-col>
        </v-row>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>

export default {
  name: "AdvancedSearch",

  data: () => ({
    text: null,
    filter: [],
    result: [],
  }),

  props: {
    color: {
      type: String,
      default: "primary",
    },

    items: {
      type: Array,
      default: () => [],
    },

    resetAdvancedSearch: {
      type: Boolean,
      default: false,
    },

    isDense: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    resetAdvancedSearch: {
      handler(newValue) {
        if (newValue) {
          this.resetAdvancedSearchData();
        }
      },
    }
  },

  methods: {
    // Emitir resultado de pesquisa
    emitResult() {
      this.$emit('result', this.result.map((el) => ({key: el.key, value: el.value})));
    },

    // Buscar label do item selecionado
    getItemLabel(key) {
      const itemIndex = this.items.findIndex(el => el.id === key);
      return itemIndex !== -1 ? this.items[itemIndex].label : '';
    },

    // Remover Filtro selecionado
    removeFilter(filterIndex) {
      this.result.splice(filterIndex, 1);

      this.emitResult();
    },

    // Limpar variáveis do componente
    resetAdvancedSearchData() {
      this.text = null;

      this.filter = [];

      this.result = [];

      this.$emit('resetAdvancedSearch', false);

      this.emitResult();
    },

    // Adicionar Filtro selecionado
    setResult(filterField) {
      if (!filterField) return;

      if (!!this.text) {
        const uniqueKey = `${filterField.id}-${Date.now()}`;

        if (!this.result.some(el => el.key === filterField.id)) {
          this.result.push({ uniqueKey, key: filterField.id, value: [this.text] });
        } else {
          const keyIndex = this.result.findIndex(el => el.key === filterField.id);

          if (!this.result[keyIndex].value.includes(this.text)) {
            this.result[keyIndex].value.push(this.text);
          }
        }
      }

      this.text = null;

      this.emitResult();
    },

    // Atualizar texto digitado
    updateText(event) {
      this.text = event;
    },
  }
}
</script>

<style>
.advancedSearch >
  .v-input__control >
    .v-input__slot {
  display: flex;
  flex-direction: row;
  max-height: 56px;
  text-overflow: ellipsis;
}

.advancedSearch >
  .v-input__control >
    .v-input__slot {
  overflow: hidden;
}

.advancedSearch.hideResult >
  .v-input__control >
  .v-input__slot {
    padding-left: 0% !important;
}

.advancedSearch >
  .v-input__control >
    .v-input__slot >
      .v-select__slot >
        .v-select__selections {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 0%;
}
</style>